/* Widgets.css */

.widgets-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2rem;
    margin: 0;
    width: 100%;
}
  
.widget {
    display: flex;
    align-items: flex-start;
}
  
/* Etsy Widget */
.etsy-widget-img {
    width: 100%;
    max-width: 300px;
    height: auto;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    object-fit: cover;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}
  
.etsy-widget-img:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
}
  
/* Buy Me a Coffee Widget */
.bmc-widget {
    display: flex;
    align-items: center;
    background-color: #FF7F7F;
    padding: 10px 20px;
    border-radius: 8px;
    border: 1px solid #E6C4A3;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-decoration: none;
    transition: all 0.3s ease;
    color: inherit; /* Ensure text color stays consistent */
    gap: 0.5rem;
}
  
.bmc-widget:hover {
    background-color: #FF9999;
    transform: translateY(-2px);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
}
  
.bmc-widget .widget-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
}
  
.bmc-widget-icon {
    width: 28px;
    height: 28px;
    filter: brightness(0.9);
}
  
.bmc-widget-text {
    font-size: 20px;
    line-height: 1;
    font-family: var(--font-main);
    color: var(--content-background);

}
  
 /* Responsive Design */
@media (max-width: 768px) {
    .widgets-container {
      flex-direction: row; /* Keep row layout */
      gap: 1rem;
      align-items: flex-start;
      flex-wrap: wrap; /* Allow wrapping if needed */
      margin-left: 0.5rem;
      margin-top: -0.5rem;
    }
  
    .etsy-widget {
      flex: 0 1 160px; /* Allow shrinking but maintain aspect ratio */
    }
  
    .etsy-widget-img {
      max-width: 160px; /* Smaller max-width for mobile */
      width: 100%;
    }
  
    .bmc-widget {
      padding: 4px 4px;
      flex-shrink: 0; /* Prevent shrinking */
      gap: 0;
    }

 
  
    .bmc-widget-text {
      font-size: 14px; /* Smaller font for mobile */
      font-weight: 300;
    }
  
    .bmc-widget-icon {
      width: 20px; /* Smaller icon for mobile */
      height: 20px;
    }
  }
  
  /* Extra small devices */
  @media (max-width: 360px) {
    .widgets-container {
      gap: 0.5rem;
      padding: 0.5rem;
    }
  
    .etsy-widget-img {
      max-width: 140px; /* Even smaller for very small screens */
    }
  
    .bmc-widget {
      padding: 4px 8px;
    }
  
    .bmc-widget-text {
      font-size: 14px;
    }
  }