/* announcement-styles.css */
:root {
    --background-color: #2C2723;
    --content-background: #201E1B;
    --text-color: #E6D9CF;
    --accent-color: #ce5656;
    --secondary-accent: #FFB3BA;
    --border-accent: #9f2112;
    --font-main: 'Montserrat', sans-serif;
    --font-content: 'Roboto', sans-serif;
    --nav-width: 800px;
}
  
.announcement-container .announcement {
    background: linear-gradient(135deg, rgba(206, 86, 86, 0.1), rgba(255, 179, 186, 0.1));
    border: 2px solid var(--accent-color);
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    max-width: 100%;
    margin: 0 auto 1rem;
    padding: 1.5rem;
    color: var(--text-color);
    animation: fadeIn 0.8s ease-in-out;
}
  
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}
  
.announcement-container .announcement-title {
    font-size: 1.4rem;
    font-weight: 600;
    color: var(--secondary-accent);
    margin-bottom: 1rem;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-bottom: 2px solid var(--secondary-accent);
    padding-bottom: 0.5rem;
    margin-top: 0;
}
  
.announcement-container .announcement-content {
    line-height: 1.2;

}

.announcement-container .announcement-content strong, b {
    color: var(--accent-color) !important;
}
  
.announcement-container .announcement-content h1,
.announcement-container .announcement-content h2,
.announcement-container .announcement-content .WordSection1 {
    color: var(--secondary-accent) !important;
    margin-bottom: 0.5rem !important;
    font-size: 1rem !important;
    font-weight: 500 !important;
    text-align: left !important; /* Force left alignment */
    font-family: var(--font-main); /* announcementUpdater*/
}
  
@media (max-width: 768px) {
    .announcement-container .announcement {
      margin: 1rem 0.5rem;
      padding: 0.5rem;
    }
  
    .announcement-container .announcement-title {
      font-size: 0.8rem;
      margin-top: 0;
    }
  
    .announcement-container .announcement-content h1,
    .announcement-container .announcement-content h2 {
      font-size: 0.7rem !important;
      margin-top: 0 !important;
      line-height: 1rem !important;
    }
}