:root {
  --background-color: #2C2723;
  --content-background: #201E1B;
  --text-color: #E6D9CF;
  --accent-color: #ce5656;
  --secondary-accent: #FFB3BA;
  --border-accent: #9f2112;
  --font-main: 'Montserrat', sans-serif;
  --font-content: 'Roboto', sans-serif;
}

.loading-spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: var(--content-background);
}

.loading-spinner {
  border: 4px solid var(--background-color);
  border-left-color: var(--accent-color);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}