:root {
  --background-color: #2C2723;
  --content-background: #201E1B;
  --text-color: #E6D9CF;
  --accent-color: #ce5656;
  --secondary-accent: #FFB3BA;
  --border-accent: #9f2112;
  --font-main: 'Montserrat', sans-serif;
  --font-content: 'Roboto', sans-serif;
  --light-peach: #d3a993;
  
  /* Font definitions */
  --font-tenor: 'Tenor Sans', sans-serif;
  --font-montserrat: 'Montserrat', sans-serif;
  --font-biryani: 'Biryani', sans-serif;
  --font-forum: 'Forum', cursive;
  --font-spectral: 'Spectral', sans-serif;
}

/* Ensure the entire page takes up the full height of the viewport */

html, body {
  margin: 0;
  padding: 0;
  font-family: var(--font-main);
  background-color: var(--background-color);
  color: var(--text-color);
  height: 100%;

}

.App {
  max-width: 1200px;
  margin: 0 auto;
}


.content {
  flex: 1; /* Push the footer down if there is little content */
}

@font-face {
  font-family: 'Roxborough CF';
  src: url('./fonts/roxborough-cf-bold.woff2') format('woff2'),  /* Modern Browsers */
       url('./fonts/roxborough-cf-bold.woff') format('woff');    /* Older Browsers */
  font-weight: bold;
  font-style: normal;
  font-display: swap; /* Optional for better loading performance */
}





@media (max-width: 768px) {
  .App {

    max-width: 100%; /* Allow full width on mobile */
  }

  
}
