.lazy-image-container {
  position: relative;
  overflow: hidden;
  background-color: var(--content-background);
  width: 100%;  /* Adjust as needed */
  height: 100%; /* Adjust as needed */
}

.lazy-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.3s ease-in-out;
}

.lazy-image.placeholder {
  filter: blur(10px);
  transform: scale(1.1);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.lazy-image-error {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8d7da;
  color: #721c24;
  font-size: 14px;
  text-align: center;
  padding: 10px;
}
