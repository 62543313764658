.search-results-dropdown {
    position: absolute; /* Position relative to the input */
    top: 100%; /* Align it right below the search input */
    left: 0;
    right: 0;
    background-color: var(--background-color);
    border: 1px solid var(--accent-color); /* Example border color */
    border-radius: 8px; /* Rounded corners */
    max-height: 300px; /* Limit height */
    overflow-y: auto; /* Scroll if too many results */
    z-index: 9999; /* Ensure it appears above other elements */
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);    /* Add shadow for better visibility */
}


.search-results-list {
    list-style: none; /* Remove bullet points */
    padding: 0;
    margin: 0;
}

.search-result-item {
    background-color: var(--background-color);
    padding: 10px;
    margin-bottom: 8px;
    border-radius: 8px; /* Rounded corners */
    display: flex; /* Flex to align image and text */
    align-items: center; /* Center-align items */
    cursor: pointer; /* Indicate clickable */
}

.result-link {
    text-decoration: none; /* Remove link underline */
    color: var(--text-color);
    display: flex; /* Align contents */
    align-items: center;
}

.result-content {
    display: flex; /* Flex container */
    align-items: center; /* Align items vertically */
}

.result-image {
    width: 50px; /* Thumbnail width */
    height: 70px; /* Thumbnail height */
    object-fit: cover; /* Ensure image covers area */
    border-radius: 4px; /* Rounded corners */
    margin-right: 10px; /* Space between image and text */
    background-color: var(--secondary-accent);
}


.result-title {
    font-size: 1rem; /* Adjust font size */
    color: var(--text-color);
    margin: 0; /* Remove default margin */
}

.no-results {
    color: var(--secondary-accent);
}

  
.result-image.fallback-text::after {
    content: 'No Image Available';
    position: absolute;
    bottom: 10px;
    font-size: 12px;
    color: var(--text-color); /* #E6D9CF */
    text-align: center;
    width: 100%;
}
  