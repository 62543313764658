/* Footer.css */
.site-footer {
  position: relative;
  background-color: var(--content-background);
  color: var(--text-color);
  margin-top: 6rem;
  padding-top: 2rem;
}

/* Divider Styles */
.site-footer-divider {
  position: absolute;
  top: -20px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding: 0 20px;
}

.site-footer-line {
  height: 1px;
  flex-grow: 1;
  background: linear-gradient(
    90deg,
    transparent,
    var(--accent-color) 20%,
    var(--secondary-accent) 50%,
    var(--accent-color) 80%,
    transparent
  );
}

.site-footer-dot {
  width: 8px;
  height: 8px;
  background-color: var(--accent-color);
  border-radius: 50%;
  position: relative;
}

.site-footer-dot::before,
.site-footer-dot::after {
  content: '';
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: var(--secondary-accent);
}

.site-footer-dot::before {
  left: -12px;
}

.site-footer-dot::after {
  right: -12px;
}

.site-footer-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem 2rem;
}

.site-footer-main {
  display: grid;
  grid-template-columns: 2fr 1fr 2fr;
  gap: 4rem;
  margin-bottom: 3rem;
}

.site-footer-section {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.section-title {
  font-family: var(--font-tenor);
  font-size: 1.25rem;
  color: var(--secondary-accent);
  margin: 0;
  padding-bottom: 0.5rem;
  position: relative;
}

.section-title::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 40px;
  height: 2px;
  background: linear-gradient(to right, var(--accent-color), var(--secondary-accent));
}

.footer-nav {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.site-footer-link {
  color: var(--light-peach);
  text-decoration: none;
  font-size: 0.95rem;
  transition: all 0.3s ease;
  display: inline-block;
  position: relative;
  padding-left: 1.5rem;
}

.site-footer-link::before {
  content: '›';
  position: absolute;
  left: 0;
  color: var(--accent-color);
  font-size: 1.2rem;
  transition: transform 0.3s ease;
}

.site-footer-link:hover {
  color: var(--secondary-accent);
  transform: translateX(5px);
}

.site-footer-link:hover::before {
  transform: translateX(3px);
}

.site-footer-bmc {
  display: inline-flex;
  align-items: center;
  gap: 0.75rem;
  background-color: var(--accent-color);
  color: var(--text-color);
  padding: 0.75rem 1.25rem;
  border-radius: 8px;
  text-decoration: none;
  transition: all 0.3s ease;
  border: 1px solid var(--light-peach);
  font-family: var(--font-content);
  width: fit-content;
}

.site-footer-bmc:hover {
  background-color: var(--secondary-accent);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.bmc-icon {
  transition: transform 0.3s ease;
}

.site-footer-bmc:hover .bmc-icon {
  transform: rotate(-10deg);
}

.site-footer-bottom {
  text-align: center;
  padding-top: 2rem;
  margin-top: 2rem;
  border-top: 1px solid rgba(230, 217, 207, 0.1);
}

.footer-credit,
.footer-copyright {
  color: var(--light-peach);
  font-size: 0.9rem;
  margin: 0.5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.heart-icon {
  color: var(--accent-color);
  animation: heartbeat 1.5s ease infinite;
}

@keyframes heartbeat {
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.1); }
}

@media (max-width: 768px) {
  .site-footer {
    padding-top: 1rem;
  }

  .site-footer-content {
    padding: 1rem;
  }

  .site-footer-main {
    grid-template-columns: 1fr;
    gap: 2rem;
    text-align: center;
  }

  .section-title::after {
    left: 50%;
    transform: translateX(-50%);
  }

  .footer-nav {
    align-items: center;
  }

  .site-footer-link {
    padding-left: 0;
  }

  .site-footer-link::before {
    display: none;
  }

  .site-footer-link:hover {
    transform: translateY(-2px);
  }

  .site-footer-bmc {
    margin: 0 auto;
    padding: 0.6rem 1rem;
    font-size: 0.9rem;
  }
}