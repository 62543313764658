:root {
  --background-color: #2C2723;
  --content-background: #201E1B;
  --text-color: #E6D9CF;
  --accent-color: #ce5656;
  --secondary-accent: #FFB3BA;
  --border-accent: #9f2112;
  --font-main: 'Montserrat', sans-serif;
  --font-content: 'Roboto', sans-serif;
  --nav-width: 800px;
}

.admin-page {
  padding: 2rem;
}

.admin-page > h2 {
  color: var(--accent-color);
  font-size: 2rem;
  margin-bottom: 2rem;
  text-align: center;
}

.admin-toggle {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.admin-toggle button {
  padding: 0.75rem 1.5rem;
  background-color: var(--content-background);
  color: var(--text-color);
  border: 1px solid var(--border-accent);
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.admin-toggle button:first-child {
  border-radius: 6px 0 0 6px;
}

.admin-toggle button:last-child {
  border-radius: 0 6px 6px 0;
}

.admin-toggle button.active {
  background-color: var(--accent-color);
  color: white;
}

.admin-toggle button:hover {
  background-color: var(--accent-color);
  color: white;
}

@media (max-width: 768px) {
  .admin-page {
    padding: 1rem;
  }

  .admin-toggle {
    flex-direction: column;
    align-items: center;
  }

  .admin-toggle button {
    width: 100%;
    margin-bottom: 0.5rem;
    border-radius: 6px;
  }
}