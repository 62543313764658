:root {
  --background-color: #2C2723;
  --content-background: #201E1B;
  --text-color: #E6D9CF;
  --accent-color: #ce5656;
  --secondary-accent: #FFB3BA;
  --border-accent: #9f2112;
  --font-main: 'Montserrat', sans-serif;
  --font-content: 'Roboto', sans-serif;
}

.npm-body {
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: var(--font-content);

}

.npm-novel-page {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.npm-novel-header {
  display: flex;
  padding: 0.5rem;  /* Reduce padding to bring the image closer to the left edge */
  background-color: var(--content-background);
  align-items: flex-start; /* Align items to the top */
}

.npm-book-image {
  width: 35%;  /* Keep this size for now */
  max-width: 120px;
  height: 120px;
  margin-right: 0.5rem;  /* Keep this margin to maintain space between image and text */
  margin-left: 0; /* Remove any left margin */
  overflow: hidden; 
  border-radius: 4px; 
}

.npm-book-info {
  width: 65%; /* Increased width to accommodate more text */
}

.npm-book-title {
  font-size: 1.1rem;
  margin: 0;
  font-weight: bold;
  margin: 0 0 0.2rem 0;

  
}

.npm-korean-title {
  font-size: 0.9rem;
  margin: 0.1rem 0 0.3rem 0;
  color: var(--secondary-accent);
  margin: 0 0 0.5rem 0;

}

.npm-stats-row {
  display: flex;
  flex-wrap: wrap;
  font-size: 0.8rem;
  gap: 0.4rem ;
}


.npm-stat-item {
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
  margin-bottom: 0.3rem;
}

.npm-stat-item .npm-icon {
  margin-right: 0.3rem;
  font-size: 0.9rem;
  color: rgb(158, 145, 145);
}

.npm-novel-summary {
  padding: 0.5rem; /* Keep the padding */
  background-color: var(--content-background);
  font-size: 0.8rem; /* Keep font size */
  line-height: 1.4; /* Keep line height */
  overflow: hidden; /* Change to auto if you want scrolling */
  text-overflow: ellipsis; /* Optional if you want text to be cut off with dots */
  display: block; /* Use block instead of -webkit-box to show all text */
  max-height: none; /* Remove max-height restriction */
  -webkit-line-clamp: unset; /* Remove line clamp */
  -webkit-box-orient: unset; /* Remove box orientation */
  height: auto; /* Allow container to auto-expand */
}

.npm-summary-content {
  max-height: 150px;
  overflow-y: auto;
  font-size: 0.8rem;
}

.npm-author-publisher {
  display: flex;
  justify-content: space-between;
  padding: 0.3rem 0.5rem;
  font-size: 0.7rem;
  background-color: var(--content-background);
}

.npm-status {
  padding: 0.3rem 0.5rem;
  font-size: 0.7rem;
  gap:0;
  background-color: var(--content-background);
}

.npm-tags-container {
  display: flex;
  overflow-x: auto;
  padding: 0.3rem 0.5rem;
  background-color: var(--content-background);
}

.npm-tag-item {
  background-color: var(--accent-color);
  color: var(--text-color);
  padding: 0.1rem 0.3rem;
  margin-right: 0.3rem;
  border-radius: 4px;
  white-space: nowrap;
  text-decoration: none;
  font-size: 0.8rem;
}





/* NovelPageMobile.css */
/* Mobile-Only CSS for FollowButton */
.npm-follow-button {
  width: 70%; /* Full width of the container */
  height:30px;
  display: flex;
  align-items: center;
  background: linear-gradient(90deg, var(--accent-color), var(--border-accent));
  border: none;
  border-radius: 8px;
  color: var(--text-color);
  font-family: 'Montserrat', sans-serif;
  font-size: 0.7rem; /* Reduced font size for mobile */
  cursor: pointer;
  justify-content: center; /* Center content */
  margin-bottom: 0; /* Adjusted margin for mobile */
  margin-top: 0.2rem;
}

.npm-follow-button.green-gradient {
  background: linear-gradient(90deg, #52C755, #056608); /* Darker green gradient */
  color: var(--text-color); /* Ensure text is visible */
}


.npm-follow-button .icon {
  margin-right: 0.3rem; /* Reduced margin for icon */
}
  
.npm-follow-button.follow-text {
  margin-right: 0.3rem; /* Reduced margin for text */
  font-weight: bold;
}
  
.npm-follow-button .divider {
  margin: 0 0.3rem; /* Reduced margin for divider */
}
  
.npm-follow-button .followers-count {
  margin-left: 0.3rem; /* Reduced margin for followers count */
}

.npm-stats-row .npm-follow-button .green-gradient .icon {
  color: var(--text-color); /* Ensure text is visible */
  font-size: 0.5rem;
}

.npm-follow-button .green-gradient .follow-text,
.npm-follow-button .green-gradient .divider,
.npm-follow-button .green-gradient .followers-count {
  color: var(--text-color); /* Ensure text is visible */
}




.npm-container .widgets-container {
  margin-top: 0.5rem;  /* Add space between follow button and widget */
}





.npm-chapters-list {
  padding: 0.5rem;
  background-color: var(--content-background);
}

.npm-chapters-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
}

.npm-sort-icon {
  cursor: pointer;
  margin-left: 1rem;

}


 /* Scrollbar Styling */
/* Custom Scrollbar Styles for WebKit Browsers on Mobile */
.npm-chapters-container {
  max-height: 300px;  /* Set a fixed height for the container */
  overflow-y: auto;   /* Enable vertical scrolling */
  padding-right: 0.5rem;  /* Optional: Adjust padding for better UI */

  /* For Firefox on Mobile */
  scrollbar-width: thin;  /* Makes the scrollbar narrower */
  scrollbar-color: var(--secondary-accent) var(--content-background);  /* Thumb color and track color */
}

/* WebKit Browsers (Chrome, Safari, Edge) - Mobile Specific */
.npm-chapters-container::-webkit-scrollbar {
  width: 6px;  /* Width of the scrollbar, thinner for mobile */
  background-color: var(--content-background);  /* Background color of the scrollbar track */
}

.npm-chapters-container::-webkit-scrollbar-thumb {
  background-color: var(--secondary-accent);  /* Color of the scrollbar thumb */
  border-radius: 10px;  /* Rounded corners of the scrollbar thumb */
  border: 1px solid var(--content-background); /* Border for a more pronounced effect */
}

.npm-chapters-container::-webkit-scrollbar-thumb:hover {
  background-color: var(--accent-color);  /* Color when hovering over the scrollbar thumb */
}

/* Scrollbar Track Styles for WebKit Browsers */
.npm-chapters-container::-webkit-scrollbar-track {
  background-color: var(--background-color);  /* Color of the scrollbar track */
  border-radius: 10px;  /* Rounded corners of the scrollbar track */
  margin: 4px;  /* Adds spacing around the scrollbar */
}

/* Ensure visibility on touch */
.npm-chapters-container::-webkit-scrollbar-track-piece {
  background-color: var(--content-background);  /* Ensure track is visible */
  border-radius: 10px;  /* Smooth corners */
}







.npm-chapters-list ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.npm-chapter-item {
  display: flex;
  justify-content: space-between;
  padding: 0.4rem 0;
  border-bottom: 1px solid var(--border-accent);
  font-size: 0.7rem;
}

.npm-chapter-link {
  color: var(--text-color);
  text-decoration: none;
}

.npm-time-ago {
  font-size: 0.6rem;
  color: var(--secondary-accent);
}

.npm-rate-novel-section {
  padding: 0.5rem;
  background-color: var(--content-background);
}


.npm-comment-wrapper {
  width: 100%;
  padding: 20px 0;
}

.npm-comment-section {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}
