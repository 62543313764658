:root {
  --background-color: #2C2723;
  --content-background: #201E1B;
  --text-color: #E6D9CF;
  --accent-color: #ce5656;
  --secondary-accent: #FFB3BA;
  --border-accent: #9f2112;
  --font-main: 'Montserrat', sans-serif;
  --font-content: 'Roboto', sans-serif;
  --light-blush: #f0d4ce;
  --card-bg: #371608;
}

.tag-results-page {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: var(--font-main);
}

.tag-results-page .section-header {
  font-size: 2rem;
  margin-bottom: 2rem;
  margin-top: 1rem;
  position: relative;
  text-transform: uppercase;
  color: var(--text-color);
  font-weight: 600;
  text-align: center;
}

.tag-results-page .novels-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
  padding: 1rem;
}

.novel-card-wrapper {
  position: relative;
  width: 100%;
  transition: transform 0.3s ease;
  border-radius: 8px;
  overflow: hidden;
}

.card-link-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  cursor: pointer;
}

.tag-results-page .novel-card {
  position: relative;
  display: flex;
  background-color: var(--card-bg);
  padding: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  transition: all 0.3s ease;
  height: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.novel-card-wrapper:hover .novel-card {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4),
              0 4px 8px rgba(255, 255, 255, 0.1) inset;
  border: 1px solid var(--accent-color);
  background-color: #4a1d0a;
}

.tag-results-page .book-cover {
  position: relative;
  z-index: 2;
  flex: 0 0 120px;
  height: 160px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.tag-results-page .book-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.novel-card-wrapper:hover .book-image {
  transform: scale(1.05);
}

.tag-results-page .novel-info {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  padding-left: 1rem;
  margin: 0;
}

.tag-results-page .novel-info h3 {
  margin: 0 0 0.5rem 0;
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--text-color);
  line-height: 1.3;
}

.tag-results-page .rating-container {
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
  font-size: 0.8rem;
  color: #FFD700;
}

.tag-results-page .novel-info p {
  margin: 0.2rem 0;
  font-size: 0.9rem;
  color: var(--light-blush);
  font-family: var(--font-content);
  line-height: 1.4;
}

/* Responsive Adjustments for Mobile View */
@media (max-width: 768px) {
  .tag-results-page {
    padding: 1rem;
  }

  .tag-results-page .section-header {
    font-size: 1.5rem;
    margin: 1rem 0;
  }

  .tag-results-page .novels-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
    padding: 0.5rem;
  }

  .tag-results-page .novel-card {
    padding: 0.8rem;
  }

  .tag-results-page .book-cover {
    flex: 0 0 100px;
    height: 140px;
  }

  .tag-results-page .novel-info h3 {
    font-size: 1rem;
  }

  .tag-results-page .novel-info p {
    font-size: 0.8rem;
  }

  .tag-results-page .rating-container {
    font-size: 0.7rem;
  }
}