:root {
    --background-color: #2C2723;
    --content-background: #201E1B;
    --text-color: #E6D9CF;
    --accent-color: #ce5656;
    --secondary-accent: #FFB3BA;
    --border-accent: #9f2112;
    --font-main: 'Montserrat', sans-serif;
    --font-content: 'Roboto', sans-serif;
}

.custom-alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.custom-alert-box {
  background-color: var(--content-background);
  color: var(--text-color);
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  font-family: var(--font-content);
  border: 2px solid var(--border-accent);
}

.custom-alert-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: var(--accent-color);
  border: none;
  color: var(--text-color);
  border-radius: 5px;
  cursor: pointer;
  font-family: var(--font-main);
}

.custom-alert-button:hover {
  background-color: var(--secondary-accent);
}
