:root {
  --background-color: #2C2723;
  --content-background: #201E1B;
  --text-color: #E6D9CF;
  --accent-color: #ce5656;
  --secondary-accent: #FFB3BA;
  --border-accent: #9f2112;
  --font-main: 'Montserrat', sans-serif;
  --font-content: 'Roboto', sans-serif;
}


.account-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  font-family: 'Roboto', sans-serif;
  color: var(--text-color);

}

.account-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  text-align: center;
  width: 100%;
}

.account-info {
  position: relative;
  width: 200px;
  height: 200px;
  margin-bottom: 1rem;
}

.account-info .profile-pic,
.account-info .default-profile-pic {
  width: 100%;
  height: 100%;
  border-radius: 16px; /* or 35% */
  object-fit: cover;
  cursor: pointer;
  border: 2px solid var(--accent-color);
  background-color: var(--content-background);
  transition: filter 0.3s ease;
}

.account-info .default-profile-pic {
  display: flex;
  justify-content: center;
  align-items: center;
}

.account-info .default-profile-pic svg {
  width: 60%;
  height: 60%;
  color: var(--text-color);
}

.account-info:hover .profile-pic,
.account-info:hover .default-profile-pic {
  filter: brightness(70%);
}

.account-info .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.account-info:hover .overlay {
  opacity: 1;
}

.account-info .overlay-button {
  background-color: transparent;
  border: 1px solid white;
  color: white;
  padding: 0.5rem 1rem;
  margin: 0.5rem;
  border-radius: 20px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.2s;
}

.account-info .overlay-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.account-header h1 {
  margin-top: 1rem;
  font-size: 2rem;
}

.account-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
}

.card {
  background-color: var(--content-background);
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.card h2 {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  color: var(--accent-color);
}

/* Add these styles for card-header */
.card-header {
  display: flex;
  justify-content: space-between; /* Aligns child elements to the ends */
  align-items: center; /* Vertically center-aligns child elements */
  margin-bottom: 1rem; /* Adds some space below the header */
}

.card-header h2 {
  margin: 0; /* Remove default margin */
}



.info-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem; /* Adjusted spacing for more visual balance */
  padding-bottom: 1.5rem; /* Adjusted spacing for more visual balance */
  border-bottom: 1px solid rgba(var(--text-color-rgb), 0.1);
}

.info-item:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.info-item input {
  flex: 2;
  width: 100%;
  background-color: var(--background-color);
  border: 1px solid var(--accent-color);
  padding: 0.75rem 1rem; /* Increased padding for larger input fields */
  border-radius: 4px;
  color: var(--text-color);
  margin-right: 1rem;
  font-size: 1.1rem; /* Increased font size */
  box-sizing: border-box;
}

.info-item label {
  font-weight: bold;
  flex: 1;
  font-size: 1.2rem;
}

.info-item span,
.info-item input {
  flex: 2;
  background-color: var(--background-color);
  border: 1px solid var(--accent-color);
  padding: 0.5rem;
  border-radius: 4px;
  color: var(--text-color);
  margin-right: 1rem;
  width: 100%;
  box-sizing: border-box;
}

.edit-button,
.save-button,
.cancel-button,
.change-password-button,
.delete-account-button {
  background-color: var(--accent-color);
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.2s, transform 0.1s;
}

.edit-button:hover,
.save-button:hover,
.cancel-button:hover,
.change-password-button:hover,
.delete-account-button:hover {
  background-color: var(--accent-color-hover);
  transform: translateY(-1px);
}

.button-group {
  display: flex; /* Use flexbox for layout */
  gap: 1rem; /* Add gap between children */
  justify-content: flex-end; /* Align buttons to the right */
}

/* check email confirmation */
.message {
  background-color: #e3f2fd;
  border: 1px solid #2196f3;
  color: #0d47a1;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 4px;
  text-align: center;
}




.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: var(--content-background);
  padding: 2rem;
  border-radius: 8px;
  width: 300px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-content h2 {
  margin-top: 0;
  margin-bottom: 1rem;
  color: var(--accent-color);
}

.modal-content input {
  width: 100%;
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid var(--accent-color);
  border-radius: 4px;
  background-color: var(--background-color);
  color: var(--text-color);
}






.card h2 {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  color: var(--accent-color);
}


.password-manager .info-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem; /* Adjusted spacing for more visual balance */
  padding-bottom: 1.5rem; /* Adjusted spacing for more visual balance */
  border-bottom: 1px solid rgba(var(--text-color-rgb), 0.1);
  background-color: var(--content-background);

}

.info-item:last-child,
.password-manager .info-item {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}


/* password section */

.password-container {
  display: flex;
  align-items: center;
}

.password-container input {
  width: 100%;
  background-color: var(--background-color);
  border: 1px solid var(--accent-color);
  padding: 0.75rem 1rem;
  border-radius: 4px;
  color: var(--text-color);
  margin-right: 0.5rem;
  font-size: 1.1rem;
  box-sizing: border-box;
}

.show-password-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: var(--text-color);
}




/* danger zone section */
.danger-zone {
  margin-top: 2rem;
  padding-right: 2.8rem;
  border: 1px solid var(--accent-color);
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  background-color: var(--content-background);

}

.danger-zone summary {
  padding: 1rem;
  background-color: var(--content-background);
  color: var(--accent-color);
  cursor: pointer;
  font-weight: bold;
  font-family: var(--font-main);
  width: 100%;
  padding-right: 2.8rem;


}

.danger-zone summary:hover {
  background-color: var(--accent-color);
  color: var(--text-color);
  padding-right: 2.8rem;

}

.danger-zone-content {
  padding: 1rem;
  background-color: var(--content-background);
  padding-right: 2.8rem;

}

.danger-zone-content p {
  margin-bottom: 1rem;
  color: var(--text-color);
}

.delete-account-button {
  background-color: transparent;
  color: var(--accent-color);
  border: 1px solid var(--accent-color);
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete-account-button:hover {
  background-color: var(--accent-color);
  color: var(--content-background);
}

.delete-account-button svg {
  margin-right: 0.5rem;
}



/* Delete Confirmation Popup */
.delete-confirm-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: #2a2a2a;
  border-radius: 8px;
  padding: 2rem;
  max-width: 400px;
  width: 90%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.popup-content h4 {
  color: var(--accent-color);
  margin-top: 0;
  margin-bottom: 1rem;
}

.popup-content p {
  color: #e0e0e0;
  margin-bottom: 1.5rem;
}

.buttons-container {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.confirm-delete-button, .cancel-delete-button {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: all 0.3s ease;
}

.confirm-delete-button {
  background-color: var(--accent-color);
  color: #1e1e1e;
  border: none;
}

.confirm-delete-button:hover {
  background-color: var(--accent-color);
}

.confirm-delete-button:disabled {
  background-color: #999;
  cursor: not-allowed;
}

.cancel-delete-button {
  background-color: transparent;
  color: #e0e0e0;
  border: 1px solid #e0e0e0;
}

.cancel-delete-button:hover {
  background-color: #e0e0e0;
  color: #1e1e1e;
}




/* Existing styles remain unchanged */

/* Mobile Styles */
@media (max-width: 768px) {
  .account-page {
    padding: 1rem;
    width: 100%;
    box-sizing: border-box;
  }

  .account-header {
    margin-bottom: 1rem;
  }

  .account-info {
    width: 120px;
    height: 120px;
  }

  .account-header h1 {
    font-size: 1.5rem;
    margin-top: 0.5rem;
  }

  .account-content {
    gap: 1rem;
    width: 100%;
  }

  .card {
    padding: 1rem;
    width: 100%;
    box-sizing: border-box;
  }

  .card h2 {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }

  .card-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .info-item {
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .info-item label {
    margin-bottom: 0.25rem;
    font-size: 1rem;
  }

  .info-item input,
  .password-container input {
    width: 100%;
    margin-right: 0;
    margin-bottom: 0.5rem;
    padding: 0.5rem;
    font-size: 1rem;
    box-sizing: border-box;
  }

  .button-group {
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
  }

  .edit-button,
  .save-button,
  .cancel-button,
  .change-password-button,
  .delete-account-button,
  .send-reset-email-button {
    width: 100%;
    padding: 0.75rem 1rem;
    font-size: 1rem;
    margin-top: 0.5rem;
  }

  .danger-zone {
    width: 100%;
    margin-top: 1rem;
    padding-right: 0;
  }

  .danger-zone summary {
    padding: 1rem;
    font-size: 1rem;
  }

  .danger-zone-content {
    padding: 1rem;
  }

  .danger-zone-content p {
    font-size: 0.9rem;
  }

  .delete-confirm-popup .popup-content {
    width: 90%;
    padding: 1rem;
  }

  .delete-confirm-popup .buttons-container {
    flex-direction: column;
    gap: 0.5rem;
  }

  .delete-confirm-popup .confirm-delete-button,
  .delete-confirm-popup .cancel-delete-button {
    width: 100%;
    padding: 0.75rem 1rem;
    font-size: 1rem;
  }

  /* Ensure consistent spacing */
  .password-manager .info-item {
    margin-bottom: 0.5rem;
    padding-bottom: 0.5rem;
  }

  /* Adjust the password container for mobile */
  .password-container {
    flex-direction: column;
    align-items: stretch;
  }

  .password-container input {
    margin-right: 0;
    margin-bottom: 0.5rem;
  }

  .show-password-button {
    align-self: flex-end;
  }
}