/* Skeleton-specific variables */
:root {
    --skeleton-bg-color: var(--content-background);
    --skeleton-highlight: rgba(230, 217, 207, 0.15);
    --skeleton-border-color: var(--accent-color);
    --skeleton-animation-color: rgba(255, 179, 186, 0.15);
  }
  
  /* Container for the entire skeleton */
  .novel-page-skeleton {
    padding: 2rem;
    background-color: var(--skeleton-bg-color);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  
  /* Novel header skeleton */
  .novel-header-skeleton {
    display: flex;
    gap: 2rem;
  }
  
  .book-image-skeleton {
    width: 150px;
    height: 225px;
    background-color: var(--skeleton-highlight);
    border-radius: 8px;
    flex-shrink: 0;
    animation: skeleton-loading 1.2s infinite linear;
  }
  
  .book-info-skeleton {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .skeleton-title {
    width: 70%;
    height: 1.5rem;
    background-color: var(--skeleton-highlight);
    border-radius: 4px;
    animation: skeleton-loading 1.2s infinite linear;
  }
  
  .skeleton-text {
    width: 100%;
    height: 1rem;
    background-color: var(--skeleton-highlight);
    border-radius: 4px;
    animation: skeleton-loading 1.2s infinite linear;
  }
  
  .skeleton-text.short {
    width: 50%;
  }
  
  .skeleton-text.long {
    width: 80%;
  }
  
  .skeleton-stats {
    display: flex;
    gap: 1rem;
  }
  
  .skeleton-stat {
    width: 100px;
    height: 1.2rem;
    background-color: var(--skeleton-highlight);
    border-radius: 4px;
    animation: skeleton-loading 1.2s infinite linear;
  }
  
  .skeleton-rating {
    width: 100px;
    height: 2rem;
    background-color: var(--skeleton-highlight);
    border-radius: 4px;
    animation: skeleton-loading 1.2s infinite linear;
  }
  
  .skeleton-follow-button {
    width: 120px;
    height: 2rem;
    background-color: var(--skeleton-highlight);
    border-radius: 20px;
    animation: skeleton-loading 1.2s infinite linear;
  }
  
  .skeleton-ad-placeholder {
    width: 100%;
    height: 50px;
    background-color: var(--skeleton-highlight);
    border-radius: 8px;
    animation: skeleton-loading 1.2s infinite linear;
  }
  
  /* Summary skeleton */
  .novel-summary-skeleton {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  /* Chapters list skeleton */
  .chapters-list-skeleton {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .chapter-item-skeleton {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .skeleton-chapter {
    width: 70%;
    height: 1rem;
    background-color: var(--skeleton-highlight);
    border-radius: 4px;
    animation: skeleton-loading 1.2s infinite linear;
  }
  
  .skeleton-time-ago {
    width: 20%;
    height: 1rem;
    background-color: var(--skeleton-highlight);
    border-radius: 4px;
    animation: skeleton-loading 1.2s infinite linear;
  }
  
  /* Rate novel section skeleton */
  .rate-novel-section-skeleton {
    display: flex;
    justify-content: center;
  }
  
  /* Comment section skeleton */
  .comment-section-skeleton {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  @keyframes skeleton-loading {
    0% {
      background-position: -200px 0;
    }
    100% {
      background-position: calc(200px + 100%) 0;
    }
  }
  
  .skeleton-title,
  .skeleton-text,
  .skeleton-stat,
  .skeleton-chapter,
  .skeleton-time-ago,
  .skeleton-rating,
  .book-image-skeleton,
  .skeleton-follow-button,
  .skeleton-ad-placeholder {
    background: linear-gradient(
      90deg,
      var(--skeleton-highlight) 25%,
      var(--skeleton-animation-color) 50%,
      var(--skeleton-highlight) 75%
    );
    background-size: 200% 100%;
    background-repeat: no-repeat;
  }
  