:root {
  --background-color: #2C2723;
  --content-background: #201E1B;
  --text-color: #E6D9CF;
  --accent-color: #ce5656;
  --secondary-accent: #FFB3BA;
  --border-accent: #9f2112;
  --font-main: 'Montserrat', sans-serif;
  --font-content: 'Roboto', sans-serif;
}

.chapter-uploader {
  font-family: var(--font-main);
  color: var(--text-color);
  background-color: var(--content-background);
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.selection-container {
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
}

.select-wrapper {
  flex: 1;
}

.select-wrapper label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.select-wrapper select {
  width: 100%;
  padding: 0.75rem;
  border-radius: 6px;
  border: 1px solid var(--border-accent);
  background-color: var(--background-color);
  color: var(--text-color);
  font-size: 1rem;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  appearance: none; /* Removes default styling */
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position: right 0.7em top 50%;
  background-size: 1.2em auto;
  padding-right: 2rem;
}

.select-wrapper select:focus {
  outline: none;
  border-color: var(--accent-color);
  box-shadow: 0 0 0 3px rgba(206, 86, 86, 0.3);
}

.select-wrapper select:hover {
  border-color: var(--accent-color);
}

.select-wrapper select option {
  background-color: var(--background-color);
  color: var(--text-color);
  padding: 0.5rem;
}

.file-upload-area {
  border: 2px dashed var(--accent-color);
  padding: 2rem;
  text-align: center;
  cursor: pointer;
  border-radius: 8px;
  background-color: var(--background-color);
  transition: background-color 0.3s ease;
}

.file-upload-area:hover {
  background-color: rgba(206, 86, 86, 0.1);
}

.file-upload-area .upload-icon {
  font-size: 3rem;
  color: var(--accent-color);
  margin-bottom: 1rem;
}

.file-upload-area p {
  margin: 1rem 0;
  font-size: 1.1rem;
}

.file-input-label {
  display: inline-block;
  margin-top: 1rem;
  padding: 0.75rem 1.5rem;
  border-radius: 6px;
  background-color: var(--accent-color);
  color: white;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.file-input-label:hover {
  background-color: var(--border-accent);
}

.file-list {
  margin-top: 2rem;
}

.file-list table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin-top: 1rem;
}

.file-list th, .file-list td {
  padding: 1rem;
  text-align: left;
  border-bottom: 1px solid var(--border-accent);
}

.file-list th {
  background-color: var(--background-color);
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.9rem;
  letter-spacing: 0.05em;
}

.file-list tr:last-child td {
  border-bottom: none;
}

.file-list input {
  width: 100%;
  padding: 0.5rem;
  background-color: var(--background-color);
  border: 1px solid var(--border-accent);
  border-radius: 4px;
  color: var(--text-color);
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.file-list input:focus {
  outline: none;
  border-color: var(--accent-color);
}

.cu-delete-button {
  background: none;
  border: none;
  color: var(--accent-color);
  cursor: pointer;
  font-size: 1.2rem;
  transition: color 0.3s ease;
}

.cu-delete-button:hover {
  color: var(--border-accent);
}

.upload-button {
  display: block;
  margin-top: 2rem;
  margin-left: auto;
  padding: 0.75rem 1.5rem;
  border: none;
  background-color: var(--accent-color);
  color: white;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 6px;
  transition: background-color 0.3s ease;
}

.upload-button:hover {
  background-color: var(--border-accent);
}

.confirmation-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.confirmation-content {
  font-family: var(--font-content);
  background-color: var(--content-background);
  color: var(--text-color);
  padding: 2rem;
  border-radius: 12px;
  text-align:left;
  max-width: 400px;
  width: 90%;
}

/* selected novel, bucket, total chapters */
.novel-text, .chapters-text, .bucket-text {
  color: var(--accent-color); /* Or any custom color */
}


.confirmation-content h3 {
  margin-top: 0;
  font-size: 1.5rem;
  text-align:center;

}

.confirmation-buttons {
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.confirm-button, .cu-cancel-button {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.confirm-button {
  background-color: #1a6e1d;
  color: white;
}

.confirm-button:hover {
  background-color: #1a6e1d;
}

.cu-cancel-button {
  background-color: var(--border-accent);
  color: white;
}

.cu-cancel-button:hover {
  background-color: #da190b;
}

/* Existing styles remain unchanged */

/* Add this media query at the end of the file */
@media (max-width: 768px) {
  .chapter-uploader {
    padding: 1rem;
  }

  .selection-container {
    flex-direction: column;
    gap: 1rem;
  }

  .select-wrapper {
    width: 100%;
  }

  .select-wrapper select {
    padding: 0.75rem;
    font-size: 16px; /* Prevent zoom on iOS */
  }

  .file-upload-area {
    padding: 1.5rem;
  }

  .file-upload-area .upload-icon {
    font-size: 2.5rem;
  }

  .file-upload-area p {
    font-size: 1rem;
  }

  .file-input-label {
    padding: 0.75rem 1rem;
    font-size: 1rem;
  }

  .file-list {
    overflow-x: auto;
  }

  .file-list table {
    min-width: 500px;
  }

  .file-list th, .file-list td {
    padding: 0.75rem 0.5rem;
    font-size: 0.9rem;
  }

  .file-list input {
    padding: 0.5rem;
    font-size: 16px; /* Prevent zoom on iOS */
  }

  .cu-delete-button {
    font-size: 1.5rem; /* Larger touch target */
    padding: 0.5rem;
  }

  .upload-button {
    width: 100%;
    margin-top: 1.5rem;
    padding: 1rem;
    font-size: 1.1rem;
  }

  .confirmation-content {
    padding: 1.5rem;
  }

  .confirmation-buttons {
    flex-direction: column;
    gap: 0.75rem;
  }

  .confirm-button, .cu-cancel-button {
    width: 100%;
    padding: 1rem;
    font-size: 1rem;
  }
}