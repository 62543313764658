/* Skeleton-specific color variables */
:root {
    --skeleton-background: var(--content-background); /* Match the skeleton background to the content background */
    --skeleton-highlight: rgba(230, 217, 207, 0.3); /* Subtle highlight color using text color */
    --skeleton-border: var(--accent-color); /* Border color for skeleton elements */
    --skeleton-animation-color: rgba(255, 179, 186, 0.3); /* Animation color based on secondary accent */
  }
  
  /* HomePageSkeleton component styles */
  .home-page-skeleton {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: var(--skeleton-background);
    padding: 2rem;
    border-radius: 8px;
    color: var(--text-color); /* Use existing text color from root */
    font-family: var(--font-main); /* Use existing font from root */
  }
  
  .announcement-skeleton,
  .latest-updates-skeleton,
  .novels-skeleton {
    background-color: var(--skeleton-background);
    padding: 1.5rem;
    border: 2px solid var(--skeleton-border);
    border-radius: 8px;
  }
  
  .skeleton-title,
  .skeleton-text,
  .skeleton-header,
  .book-image-skeleton {
    width: 100%;
    height: 1.5rem;
    background-color: var(--skeleton-highlight);
    border-radius: 4px;
    margin-bottom: 1rem;
    opacity: 0.7;
  }
  
  .book-image-skeleton {
    width: 100%;
    height: 200px;
    border-radius: 8px;
    margin-bottom: 1rem;
  }
  
  /* Skeleton Loading Animation */
  @keyframes skeleton-loading {
    0% {
      background-position: -200px 0;
    }
    100% {
      background-position: calc(200px + 100%) 0;
    }
  }
  
  .skeleton-title,
  .skeleton-text,
  .skeleton-header,
  .book-image-skeleton {
    animation: skeleton-loading 1.2s infinite linear;
    background: linear-gradient(
      90deg,
      var(--skeleton-highlight) 25%,
      var(--skeleton-animation-color) 50%,
      var(--skeleton-highlight) 75%
    );
    background-size: 200% 100%;
    background-repeat: no-repeat;
  }
  
  .updates-grid-skeleton,
  .novels-grid-skeleton {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1.5rem;
  }
  
  .update-card-skeleton,
  .novel-card-skeleton {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: var(--skeleton-background);
    padding: 1rem;
    border-radius: 8px;
    border: 1px solid var(--skeleton-border);
  }
  
  .update-info-skeleton,
  .novel-info-skeleton {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .skeleton-title,
  .skeleton-text,
  .skeleton-header,
  .book-image-skeleton {
    animation: skeleton-loading 1.2s infinite linear;
  }
  
  /* Apply the subtle animation with a gradient */
  .skeleton-title,
  .skeleton-text,
  .skeleton-header,
  .book-image-skeleton {
    background: linear-gradient(
      90deg,
      var(--skeleton-highlight) 25%,
      var(--skeleton-animation-color) 50%,
      var(--skeleton-highlight) 75%
    );
    background-size: 200% 100%;
    background-repeat: no-repeat;
  }
  