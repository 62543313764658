:root {
    --background-color: #2C2723;
    --content-background: #201E1B;
    --text-color: #E6D9CF;
    --accent-color: #ce5656;
    --secondary-accent: #FFB3BA;
    --border-accent: #9f2112;
    --font-main: 'Montserrat', sans-serif;
    --font-content: 'Roboto', sans-serif;
    --nav-width: 800px;
}
  
.announcement-updater {
    font-family: var(--font-main);
    background-color: var(--background-color);
    color: var(--text-color);
    padding: 2rem;
    border-radius: 8px;
    max-width: 100%;
    margin: 0 auto;
 }
  
.announcement-updater h2 {
    color: var(--accent-color);
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
}
  
.form-group {
    margin-bottom: 1.5rem;
}
  
.form-group label {
    display: block;
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
    font-weight: bold;
}
  
.input-wrapper {
    width: 100%; /* Ensures all inputs take up the same width */
}
  
.form-group input[type="text"],
.form-group input[type="file"] {
    width: 100%;  /* Take full width of the parent container */
    padding: 0.75rem;
    font-size: 1rem;
    background-color: var(--content-background);
    border: 1px solid var(--border-accent);
    border-radius: 4px;
    color: var(--text-color);
    box-sizing: border-box; /* Ensures padding doesn't overflow */
}
  
.file-input-group {
    display: flex;
    gap: 1rem;
    align-items: center; /* Vertically align the input and button */
}
  
.file-input-group input[type="file"] {
    flex-grow: 1;
}
  
.btn {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
  
.btn-primary, /* update announcement button */
.btn-secondary {  /* clear button */
    background-color: var(--accent-color);
    color: white;
    border: 1px solid var(--border-accent);
}

  
.btn-preview {
    background-color: #726E6D;
    color: var(--text-color);
    border: 1px solid var(--border-accent);
    transition: background-color 0.3s ease;
}
  
.btn-preview.file-added {
    background-color: var(--accent-color); /* Change color when a file is added */
}
  
.btn-preview.disabled {
    background-color: #3A3736; /* Smokey color when disabled */
    cursor: not-allowed;
    opacity: 0.7;
}
  
.button-group {
    display: flex;
    gap: 1rem;
    margin-top: 1.5rem;
}

.button-group button {
    flex: 1;
}
  
  
.file-name {
    margin-top: 0.5rem;
    font-size: 0.9rem;
    color: var(--secondary-accent);
}
  
.error-message, .status-message {
    margin-top: 1rem;
    padding: 0.75rem;
    border-radius: 4px;
    font-weight: bold;
}
  
.error-message {
    background-color: rgba(255, 0, 0, 0.1);
    color: #ff6b6b;
 }
  
.status-message {
    background-color: var(--content-background);
    border: 1px solid var(--border-accent);
    border-radius: 4px;
}
  
.current-announcement {
    margin-top: 2rem;
    padding: 1.5rem;
    background-color: var(--content-background);
    border-radius: 8px;
}

.current-announcement h4 {
    color: var(--accent-color);
    font-size: 1.5rem;
    margin: 0;
}
  
.current-announcement h3 {
    color: var(--accent-color);
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.current-announcement strong, b {
    color: var(--accent-color);
}
  
.announcement-preview {
    margin-top: 1.5rem;
    color: var(--accent-color);
    font-size: 1.5rem;
}
  
  
.announcement-title {
    color: var(--secondary-accent);
    font-size: 1.3rem;
    margin-bottom: 1rem;
}
  
.announcement-content {
    color: var(--text-color);
    font-family: var(--font-content);
    line-height: 1.6;
}
  
@media (max-width: 768px) {
    .announcement-updater {
      padding: 1rem;
    }
  
    .button-group {
      flex-direction: column;
    }
  
    .btn {
      width: 100%;
    }
  }