.mobile-scroll-arrows {
    position: fixed;
    right: 16px;
    bottom: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
  }
  
  .mobile-scroll-arrows.visible {
    opacity: 1;
    visibility: visible;
  }
  
  .scroll-arrow {
    width: 40px;
    height: 40px;
    background-color: rgba(46, 46, 46, 0.5);
    border: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .scroll-arrow:hover,
  .scroll-arrow:focus {
    background-color: rgba(46, 46, 46, 0.8);
  }
  
  .scroll-arrow svg {
    color: #ffffff;
  }
  
  /* Hide on larger screens */
  @media screen and (min-width: 769px) {
    .mobile-scroll-arrows {
      display: none;
    }
  }
  
  /* Adjust for smaller mobile screens */
  @media screen and (max-width: 320px) {
    .mobile-scroll-arrows {
      right: 8px;
      bottom: 8px;
    }
  
    .scroll-arrow {
      width: 36px;
      height: 36px;
    }
  }