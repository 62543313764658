:root {
  --background-color: #2C2723;
  --content-background: #201E1B;
  --text-color: #E6D9CF;
  --accent-color: #ce5656;
  --secondary-accent: #FFB3BA;
  --border-accent: #9f2112;
  --font-main: 'Montserrat', sans-serif;
  --font-content: 'Roboto', sans-serif;
  --discord-color: #7289DA;
  --discord-hover-color: #5E73BC;
}

/* Modal Styling */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(44, 39, 35, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Modal Content Styling */
.modal-content {
  background-color: var(--content-background);
  padding: 2.5rem 3rem;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  max-width: 400px;
  width: 100%;
  color: var(--text-color);
  font-family: var(--font-main);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

.login-form, .signup-form {
  width: 100%;
}

/* Modal Header Styling */
.modal-content h2 {
  margin-bottom: 0.75rem;
  font-size: 24px;
  text-align: center;
  width: 100%;
  max-width: 280px;
  color: var(--text-color);
  font-weight: bold;
  margin-top: -0.4rem;
  font-family: var(--font-main);
}

.modal-content p {
  color: var(--secondary-accent);
  margin-top: -0.4rem;
  font-family: var(--font-main);
  margin-bottom: 20px;
  font-weight: bold;
}

.switch-modal-button, .switch-modal-link {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  color: var(--secondary-accent);
  font-family: var(--font-main);
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
}

.switch-modal-link:hover, .switch-modal-button:hover {
  text-decoration: underline;
}

/* Input Fields */
.login-input, .signup-input, .modal-input {
  width: 100%;
  max-width: 100%;
  padding: 0.75rem 1.25rem;
  margin: 1rem 0;
  background-color: var(--background-color);
  color: var(--text-color);
  border: 1px solid var(--accent-color);
  border-radius: 5px;
  font-size: 16px;
  display: block;
  box-sizing: border-box;
}

/* Button Styling */
.login-button, .signup-button, .modal-button {
  width: 100%;
  padding: 0.75rem;
  background-color: var(--accent-color);
  border: none;
  border-radius: 5px;
  color: var(--text-color);
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  margin: 1rem 0;
  display: block;
  transition: background-color 0.3s ease;
}

.login-button:disabled, 
.signup-button:disabled,
.modal-button:disabled {
  background-color: var(--secondary-accent);
  color: var(--background-color);
  cursor: not-allowed;
}

.modal-button:hover, .login-button:hover, .signup-button:hover {
  background-color: var(--border-accent);
}

/* Login Options Styling */
.login-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.5rem 0;
  margin: 1rem 0;
  box-sizing: border-box;
}

.login-options label {
  display: flex;
  align-items: center;
  font-family: var(--font-main);
  font-size: 0.9rem;
  color: var(--text-color);
  margin: 0;
  padding: 0;
  white-space: nowrap;
}

.login-options a {
  font-family: var(--font-main);
  font-size: 0.9rem;
  font-weight: bold;
  color: var(--secondary-accent);
  text-decoration: none;
  cursor: pointer;
  margin-left: auto;
  white-space: nowrap;
}

.login-options a:hover {
  text-decoration: underline;
}

.login-options input[type="checkbox"] {
  width: 18px;
  height: 18px;
  margin-right: 8px;
  cursor: pointer;
  accent-color: var(--accent-color);
  vertical-align: middle;
  margin-bottom: 0;
}

.remember-me-label {
  margin-right: 0.5rem;
  margin-top: 0.5rem;
}

.error-message {
  color: var(--accent-color) !important;
}


.password-input-container {
  position: relative;
  width: 100%;
}

.password-input-container .modal-input,
.password-input-container .signup-input {
  padding-right: 40px; /* Make room for the toggle button */
}

.password-toggle-btn {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: var(--secondary-accent);
  cursor: pointer;
  font-size: 1.2rem;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1; /* Ensure the button is above the input */
}

.password-toggle-btn:hover {
  color: var(--accent-color);
}

/* Adjust input padding for icon space */
.password-input {
  padding-right: 40px !important; /* Override any existing right padding */
}








/* Login Divider Styling */
.login-divider {
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--secondary-accent);
  margin: 1rem 0;
  width: 100%;
}

.login-divider::before,
.login-divider::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid var(--secondary-accent);
}

.login-divider span {
  padding: 0 0.5rem;
}

/* Discord Login/Signup Button Styling */
.discord-login-button, .discord-signup-button {
  background-color: var(--discord-color);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0.75rem;
  border: none;
  border-radius: 5px;
  color: var(--text-color);
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  margin: 1rem 0;
  transition: background-color 0.3s ease;
}

.discord-login-button:hover, .discord-signup-button:hover {
  background-color: var(--discord-hover-color);
}

.discord-icon {
  margin-right: 0.5rem;
  font-size: 1.2rem;
}

/* Register/Login Prompt Styling */
.register-prompt, .login-prompt {
  text-align: center;
  margin-top: 1.5rem;
  color: var(--secondary-accent);
  font-family: var(--font-main);
  font-size: 0.9rem;
}

/* Email Verification Popup Styling */
.email-verification-modal {
  z-index: 1100;
}

.email-verification-content {
  text-align: center;
}

.email-verification-content h2 {
  margin-bottom: 1rem;
}

.email-verification-content p {
  margin-bottom: 1.5rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .login-options a {
    font-size: 0.8rem;
  }

  .modal-content {
    padding: 2rem;
  }
}