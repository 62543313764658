:root {
  --background-color: #2C2723;
  --content-background: #201E1B;
  --text-color: #E6D9CF;
  --accent-color: #ce5656;
  --secondary-accent: #FFB3BA;
  --border-accent: #9f2112;
  --font-main: 'Montserrat', sans-serif;
  --font-content: 'Roboto', sans-serif;
}




/* Home Page General Styles */
.home-page {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: var(--font-main);

}




.home-page .section-header {
  font-family: 'Tenor Sans', sans-serif;
  font-size: 2rem;
  margin-bottom: 2rem;
  margin-top: 3rem;
  position: relative;
  color: var(--secondary-accent);
  text-transform: uppercase; /* Ensure headers are in uppercase */
}

.home-page .section-header:after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, var(--accent-color), var(--secondary-accent));
  position: relative;
  bottom: -0.5rem;
  left: 0;
}


/* Specific header for the novels section */
.section-header-wrapper {
  font-family: 'Tenor sans', sans-serif;
  display: flex; /* Block by default for desktop */
  position: relative;
  margin-bottom: 2rem;
  gap: 1.5rem;
}

.section-header-wrapper h2 {
  font-size: 2rem;
  color: var(--secondary-accent);
  text-transform: uppercase;
  margin: 0;
}

.section-header-wrapper .novel-in-progress {
  font-size: 1.1rem;
  color: var(--secondary-accent);
  margin-top: 0.5rem; /* Slight margin for desktop spacing */
  font-style:italic;
}

/* Divider under NOVELS */
.section-header-wrapper::after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, var(--accent-color), var(--secondary-accent));
  position: absolute;
  bottom: -0.5rem;
  left: 0;
}










.book-cover {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 1rem;
  overflow: hidden;
}

.book-cover .book-image {
  width: 100%;
  height: 100%;
  background-size: cover;
  filter: brightness(1.1);

}

/* Updates Section */

.updates-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Fixed 5 columns */
  gap: 1.5rem;
  margin-bottom: 7rem; /* Space between sections */
  padding: 1rem;
}

.update-card {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  max-width: 300px;
  padding: 1rem;
  position: relative;
  background-color: var(--content-background);
}

.update-card::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 8px;
  outline: 2px solid var(--accent-color);
  outline-offset: -2px;
  pointer-events: none;

}


.update-card .book-cover {
  width: 100%;
  aspect-ratio: 3 / 4;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.update-card .book-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.update-info {
  /* Removed padding to allow child elements to use full width */
  padding: 0; 
  color: var(--light-blush, #f0d4ce); 
  text-align: center;
  width: 100%;
  box-sizing: border-box; /* Include padding and border in width */
}

.update-info h3 {
  margin: 0 0 1rem; /* Add more spacing */
  font-family: 'Montserrat';
  font-size: 1.2rem;
}


/* Chapter Links Styling */
.chapter-link {
  background-color: var(--accent-color);
  color: var(--text-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 100;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border-radius: 12px;
  text-decoration: none;
  font-family: 'Biryani', sans-serif;
  font-size: 0.9rem;
  transition: background-color 0.3s ease, color 0.3s ease;
  outline: 2px solid #f0d4ce; /* warm beige / Add this line for the outline */
  outline-offset: -2px; /* Add this line to position the outline inside the border */
}


.chapter-link:hover {
  background-color: var(--secondary-accent);
  color: black;
  font-weight: bold;
  outline-color: var(--blush); /* Change outline color on hover */

}

.chapter-link .upload-time {
  flex-shrink: 0;
  margin-right: 0.5rem;
  font-style: italic;
}

.chapter-link .chapter-number {
  text-align: right;
}






  
/* Novels Section */
.novels-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Fixed 4 columns */
  gap: 1rem; /* Space between book cards */
  margin-top: 1rem; /* Margin at the top of the section */
  margin-bottom: 0; /* Ensure no extra margin at the bottom */
  padding: 0; /* Remove padding to prevent extra space */
  width: 100%; /* Ensure full width */
  box-sizing: border-box; /* Include padding in width */
  justify-items: start; /* Align items in grid */
}
  
/* Novel Card */
/* Novel Card Wrapper */
.novel-card-wrapper {
  position: relative;
  width: 100%;
}

/* Overlay Link */
.card-link-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  text-decoration: none;
}

/* Update Novel Card */
.novel-card {
  position: relative;
  z-index: 2;
  pointer-events: none; /* This allows clicks to pass through to the overlay */
}

/* Enable pointer events for tags */
.novel-info .tags {
  position: relative;
  z-index: 3;
  pointer-events: auto; /* Re-enable pointer events for tags */
}

.novel-info .tag-item {
  position: relative;
  z-index: 3;
  pointer-events: auto; /* Ensure tags are clickable */
}

/* Ensure the hover state works with the overlay */
.novel-card-wrapper:hover .novel-card {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(206, 86, 86, 0.3);
  background-color: #4a1d0a;
  border: 1px solid var(--accent-color);
}

.novel-card {
  background-color: #371608; /* Background color for novel card */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  border-radius: 8px; /* Rounded corners */
  display: flex; /* Use flexbox for layout */
  flex-direction: row; /* Row layout for side-by-side content */
  align-items: flex-start; /* Align items to the top */
  width: 100%; /* Full width of grid cell */
  height: 200px; /* Fixed height for uniform appearance */
  max-width: 100%; /* Allow it to take the full width available in the grid */
  box-sizing: border-box; /* Include padding in width */
  padding: 1rem; /* Set the general padding */
  padding-bottom: 0.5rem; /* Override bottom padding */ 
  transition: box-shadow 0.3s ease, transform 0.3s ease; /* Smooth transition for hover effect */
}
  
.novel-card .novel-info .book-title-link {
  color: var(--text-color);
}

.novel-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(206, 86, 86, 0.3);
  background-color: #4a1d0a; /* Slightly lighter background on hover */
  border: 1px solid var(--accent-color);
}
  
/* Book Cover in Novel Card */
.novel-card .book-cover {
  flex: 0 0 100px; /* Adjust width for the cover */
  height: 150px; /* Fixed height for the cover */
  border-radius: 8px; /* Rounded corners */
  overflow: hidden; /* Hide overflow */
  object-fit: cover; /* Ensure the image covers the entire area */
  padding: 0.1rem;
  margin-bottom: 0 ;
  transition: transform 0.3s ease-in-out;
}

.novel-card:hover .book-cover {
  transform: scale(1.05);
}

/* Novel Info in Novel Card */
.novel-info {
  display: flex; /* Flexbox to align items */
  flex-direction: column; /* Column direction */
  align-items: flex-start; /* Align items to the left */
  justify-content: flex-start; /* Align to the top */
  flex: 1; /* Take the remaining space */
  padding: 0.5rem 0; /* Add padding */
  width: calc(100% - 120px); /* Adjust width for info section */
  height: 100%; /* Ensure full height */
  width: 100%; /* Full width of the info section */
  padding-left: 0.4rem;
}
 
/* Novel Stats with Icons */
.novel-stats {
  display: flex; /* Use Flexbox to display in a row */
  gap: 0.5rem; /* Add some space between the items */
  margin-top: 0.2rem; /* Add some margin at the top */
  color: var(--light-blush);
  font-size: 0.9rem; /* Adjust font size */

}

/* Novel Stat */
.novel-stat {
  display: flex; /* Display in a row */
  align-items: center; /* Align items vertically */
  gap: 0.3rem; /* Space between icon and text */
}

.novel-info h3 {
  margin: 0 0 0.2rem; /* Reduce margin */
  font-family: 'Montserrat';
  color: var(--light-blush);
  text-align: left; /* Align to the left */
}
  
.novel-info p {
  margin: 0.1rem 0; /* Reduce margin */
  font-size: 0.9rem; /* Smaller font size */
  color: var(--light-blush);
  overflow: hidden;
}
  
.novel-info .tag-item {
  color: var(--text-color);
 
  padding: 0.2rem 0.4rem; /* Reduce padding */
  border-radius: 4px; /* Rounded corners */
  margin: 0.1rem 0; /* Reduce margin */
  max-width: 80px; /* Limit width to ensure small size */
  padding: 0.2rem 0.3rem; /* Smaller padding for compact appearance */
  text-decoration: none; /* Remove underline */
  background-color: var(--border-accent); /* Hover background color */
  border-radius: 4px; /* Rounded  */
  text-decoration: none; /* Remove underline */
  font-size: 0.7rem; /* Adjust font size */
  transition: all 0.2s ease-in-out;
  outline: 2px solid #EAD8C8; /* Outline for tag */
  outline-offset: -2px; /* Add this line to position the outline inside the border */

}

.novel-info .tag-item:hover {
  background-color: var(--secondary-accent); /* Hover background color */
  color: var(--dark-brown); /* Hover text color */
  text-decoration: none; /* Ensure no underline on hover */

}

  
.novel-info .tag-item a {
  text-decoration: none; /* Remove underline from link */
  color: inherit; /* Inherit color from parent */
}





/* Update for the Novels section to match Latest Updates */

.book-title-link a {
  color: var(--light-blush);  /* Ensure the color is consistent */
}

.book-title-link {
  font-family: 'Montserrat';
  font-size: 1rem;  /* Ensure the font size is the same */
  text-decoration: none;  /* Remove underline */
  display: block;  /* Ensure it takes up full width like the other titles */
  margin-bottom: 0.2rem;
  transition: color 0.2s ease-in-out;

}

.book-title-link:hover,
.book-title-link:visited,
.book-title-link:active {
  color: var(--light-peach);  /* Ensure the color doesn't change */
  text-decoration: none;  /* Keep the text decoration consistent */
}

.novel-info .tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem; /* Add spacing between tags */
}








.novel-stats {
  display: flex; /* Use Flexbox to display in a row */
  gap: 1rem; /* Add some space between the items */
  margin-top: 0.2rem; /* Add some margin at the top */
  color: var(--light-blush);
}
  
.novel-stat {
  display: flex; /* Display in a row */
  align-items: center; /* Align items vertically */
  margin-bottom: 0.3rem;
}
  
  
.stats {
  display: flex;
  gap: 1.5rem; /* Adjust gap to provide more space */
  flex-wrap: wrap; /* Allow wrapping to prevent overflow */
  align-items: center; /* Align items vertically center */
 
}

/* Add subtle hover effect to stats */
.novel-card:hover .novel-stats {
  color: var(--secondary-accent);
}
  
.total-views, .total-chapters {
  display: flex;
  align-items: center;
  font-family: var(--font-main);
  color: var(--text-color);
  font-size: 1.2rem;
  white-space: nowrap; /* Prevent text from wrapping */

}
  
.total-views span, .total-chapters span {
  margin-left: 0.3rem; /* Add margin to the left for spacing */
  
}


/* Rating Container */
.rating-container {
  display: flex; /* Use Flexbox to align children */
  align-items: center; /* Align items vertically */
  margin-top: -0.5rem;  /* Remove negative margin to position properly */
  margin-bottom: 0rem; /* Adjust margin if needed */
}

.novel-info .rating-result {
  display: flex; /* Display stars and rating value in a row */
  align-items: center; /* Align items vertically */
  gap: 0.5rem; /* Add some space between stars and the numeric rating */
}

.novel-info .rating-result .star {
  font-size: 1rem;
  margin-right: 0.1rem;
  cursor: default; /* Regular pointer */
}

.novel-info .rating-result .rating-value {
 display: none;
}






.tags-footer {
  margin-top: 10rem;
  padding-top: 4rem;
  padding: 2rem auto;
  margin-bottom:10rem;  

}

.tags-list {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}


.tag-item a {
  text-decoration: none; /* Ensure link style consistency */
  color: inherit;
}

.tag-item:hover {
  background-color: var(--secondary-accent);
  color: var(--dark-brown);
}












/* Responsive Adjustments for Mobile View */
@media (max-width: 768px) {
  .home-page {
    padding: 0; /* Remove padding to allow full-width usage */
    max-width: 100%; /* Ensure it uses the full width of the screen */
    margin: 0; /* Remove auto-centering margin */
    color: var(--text-color);
    font-family: var(--font-main);
    box-sizing: border-box; /* Include padding and border in width calculation */
    overflow-x: hidden; /* Prevent horizontal scrolling */

  }
  


  .home-page .section-header {
    font-size: 1.5rem; /* Adjust font size for smaller screens */
    margin-bottom: 1.5rem; /* Adjust margin */
    margin-top: 2rem; /* Adjust margin */
    text-align: center; /* Center-align the text */
    position: relative; /* Keep relative positioning */

  }

  .home-page .section-header:after {
    content: '';
    display: block;
    width: 50%;
    height: 1px;
    margin: 0.5rem auto 0; /* Center the border */
  }


  .section-header-wrapper {
    display: flex; /* Use Flexbox */
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center align content */
    justify-content: center; /* Center align vertically */
    margin-top: 5rem; /* Top margin */
    margin-bottom: 1rem; /* Bottom margin */
    text-align: center; /* Center text alignment */
    padding: 0 1rem; /* Padding for left and right */


  }

  .section-header-wrapper h2 {
    font-size: 1.5rem; /* Match size to "GENRE" header */
    color: var(--secondary-accent); /* Ensure the color matches */
    margin: 0; /* Remove margin */
    text-transform: uppercase; /* Keep text uppercase */
    position: relative; /* Position relative for line */
    width: 100%; /* Ensure full width to center the line */
  }

  .section-header-wrapper .novel-in-progress {
    font-size: 0.8rem; /* Smaller text size */
    color: var(--secondary-accent); /* Ensure the color matches */
    margin-top: -1.3rem; /* Reduce space above */
    font-style: italic; /* Italic style */
  }


  /* Style the line underneath "NOVELS" */
  .section-header-wrapper h2::after {
    content: '';
    display: block;
    width: 100%; /* Adjust line width to extend slightly beyond text */
    height: 1px; /* Thinner line */
    margin: 0.5rem auto; /* Margin to space out, center horizontally */
    position: relative; /* Relative to align with text */
    top: 5px; /* Slightly adjust for spacing */
    background-color: #ff5c8d;
  }


  .section-header-wrapper::after {
    display:none;
  }

  .updates-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Adjusted to fit smaller screen sizes */
    gap: 1rem; /* Adjust the gap between the grid items */
    margin: 0 auto; /* Center the grid */
    padding: 0 1rem; /* Add padding on the sides */
    max-width: 100%; /* Ensure it uses full width */
    box-sizing: border-box; /* Include padding in width calculation */
    justify-items: center; /* Center items within the grid cells */
  }

  .update-card {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%; /* Allow the card to be responsive */
    max-width: 220px; /* Adjust the max-width to make it smaller */
    padding: 1rem;
    margin: 0 auto; /* Center each card individually */
  }

 
  .update-card .book-cover {
    width: 100%;
    aspect-ratio: 3 / 4;
    max-width: 200px; /* Smaller image size */
    margin: 0 auto; /* Center the image */
  }

  .update-card .book-image {
    width: 100%; /* Full width of the cover */
    height: 100%; /* Full height to maintain aspect ratio */
    object-fit: cover; /* Ensure it fills the container */
  }

  /* Avoid applying global styles to other sections */
  .novel-header .book-image,
  .novel-page .book-image {
    width: 100%; /* Full width of the cover */
    height: 100%; /* Full height to maintain aspect ratio */
    object-fit: cover; /* Ensure it fills the container */
  }
  
  
  .update-info h3 {
    font-size: 0.9rem;
    text-align: center;
    margin: 0.5rem 0; /* Adjust margin for spacing */

  }





  .chapter-link {
    padding: 0.3rem; /* Reduce padding */
    font-size: 0.7rem; /* Smaller font size */
    margin-bottom: 0.5rem; /* Adds gap between links */
  }
  
  .chapter-link .chapter-number {
    padding-left: 10px; 
  }




  .novel-card-wrapper {
    width: calc(100% - 4rem);
    max-width: 350px;
  }
  
  .novel-card-wrapper:hover .novel-card {
    transform: translateY(-3px); /* Smaller lift on mobile */
  }

  
  .novels-grid {
    display: grid;
    grid-template-columns: 1fr; /* One column layout for smaller screens */
    gap: 1rem; /* Space between cards */
    justify-items: center; /* Center the items within the grid */
    padding: 0; /* Remove padding to prevent any extra space */
    width: 100%; /* Ensure it uses the full width */
    box-sizing: border-box; /* Include padding and border in width */
  }
  
  .novel-card {
    background-color: #371608; /* Background color for novel card */
    padding: 0.5rem; /* Reduced padding for mobile view */
    width: calc(100% - 4rem); /* Full width with some space around */

    max-width: 350px; /* Optional: set a max-width to make the card compact */
    border: none; /* Remove borders */
    display: flex; /* Use flexbox for better control */
    flex-direction: row; /* Row layout for side-by-side content */
    align-items: stretch; /* Align items to fill the height */
    margin: 0; /* Remove margin */
    box-sizing: border-box; /* Ensure padding and border are included in width */
    padding: 1.5rem; /* Maintain padding for content spacing */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3), /* Main shadow */
                0 1px 4px rgba(255, 255, 255, 0.1) inset; /* Subtle inner shadow */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for effects */

  }

  .novel-card:hover {
    transform: translateY(-5px); /* Lift the card slightly */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4), /* Increased shadow for hover */
                0 4px 8px rgba(255, 255, 255, 0.2) inset; /* Enhanced inner shadow */
    border: 1px solid var(--accent-color); /* Change border color on hover */
    cursor: pointer; /* Show clickable indication */
  }
  
  .novel-card .book-cover {
    flex: 0 0 100px; /* Fixed width for the cover */
    width: 100px; /* Set a fixed width */
    aspect-ratio: 3 / 4; /* Maintain consistent aspect ratio */
    border-radius: 8px; /* Rounded corners */
    margin-right: 1rem; /* Space between image and text */
    object-fit: cover; /* Ensure the image covers the entire area */
    height: 100%; /* Ensure book cover takes the full height */
  }

  .novel-card:hover .book-cover {
    transform: scale(1.03); /* Smaller scale effect on mobile */
  }

  .book-title-link {
    font-size: 0.8rem;  /* Ensure the font size is the same */
    text-decoration: none;  /* Remove underline */
    display: block;  /* Ensure it takes up full width like the other titles */
  }
  
  .novel-info {
    display: flex; /* Flexbox to align items */
    flex-direction: column; /* Column direction */
    justify-content: flex-start; /* Align to the top */
    flex: 1; /* Take the remaining space */
    margin: 0; /* Remove margin to align with book cover */
    background-color: transparent; /* Transparent background */
    padding: 0; /* Remove padding */

  }
  

  
  .novel-info p {
    margin: 0.1rem 0; /* Reduced margin for tighter spacing */
    color: var(--light-blush);
    overflow: hidden;
  }
  
  .novel-info .tags {
    display: flex; /* Flexbox for tags */
    flex-wrap: wrap; /* Allow tags to wrap */
    gap: 0.3rem; /* Spacing between tags */
    margin-top: 0.5rem; /* Space above tags */
  }
  
  .novel-info .tag-item {
    color: var(--text-color);
    padding: 0.2rem 0.3rem; /* Smaller padding for compact appearance */
    font-size: 0.6rem; /* Smaller font size for tags */
    border-radius: 4px; /* Rounded corners */
    text-decoration: none; /* Remove underline */
    outline: 2px solid var(--light-blush); /* Outline for tag */
    outline-offset: -2px; /* Position the outline inside the border */
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition */
  }
  
  .novel-info .tag-item:hover {
    background-color: var(--secondary-accent); /* Hover background color */
    color: var(--dark-brown); /* Hover text color */
  }
  
  .novel-stats {
    display: flex; /* Use Flexbox to display in a row */
    gap: 0.5rem; /* Add some space between the items */
    margin-top: 0.2rem; /* Add some margin at the top */
    color: var(--light-blush);
    
  }

  .novel-stat {
    font-size: 0.8rem;
    margin-top:0.3rem;
    display: flex; /* Display in a row */
    align-items: center; /* Align items vertically */
    gap: 0.3rem; /* Space between icon and text */
  }
  

  
  .rating-container {
    display: flex; /* Use Flexbox to align children */
    align-items: center; /* Align items vertically */
    margin-top: -0.3rem; /* Adjust as needed */
    margin-bottom: -0.5rem; /* Adjust as needed */
    font-size: 0.5rem; /* Smaller font size */
  }
  
  .novel-info .rating-result .star {
    font-size: 0.9rem; /* Smaller star size */
    margin-right: 0.2rem;
    cursor: default; /* Regular pointer */
  }
  
  .novel-info .rating-result .rating-value {
    display: none; /* Hide the rating value */

  }


  .tags-footer {
    margin-top: 10rem;
  
    padding: 2rem;
    margin-bottom:10rem;  


  }
  



 

 
  
   
  
  
}