/* NovelPageMobileSkeleton.css */
.npm-skeleton-page {
    padding: 1rem;
    background-color: var(--background-color);
  }
  
  .npm-skeleton-header {
    display: flex;
    gap: 1rem;
    margin-bottom: 1.5rem;
  }
  
  .npm-skeleton-image {
    width: 100px;
    height: 140px;
    background: var(--content-background);
    border-radius: 8px;
  }
  
  .npm-skeleton-info {
    flex: 1;
  }
  
  .npm-skeleton-title,
  .npm-skeleton-subtitle,
  .npm-skeleton-line,
  .npm-skeleton-stat-item,
  .npm-skeleton-chapter-item,
  .npm-skeleton-rating,
  .npm-skeleton-comment-item {
    background: var(--content-background);
    border-radius: 4px;
  }
  
  .npm-skeleton-title {
    width: 70%;
    height: 20px;
    margin-bottom: 0.5rem;
  }
  
  .npm-skeleton-subtitle {
    width: 50%;
    height: 15px;
    margin-bottom: 1rem;
  }
  
  .npm-skeleton-stats {
    display: flex;
    gap: 1rem;
  }
  
  .npm-skeleton-stat-item {
    width: 50px;
    height: 15px;
  }
  
  .npm-skeleton-summary,
  .npm-skeleton-author-publisher {
    margin-bottom: 1.5rem;
  }
  
  .npm-skeleton-line {
    width: 100%;
    height: 15px;
    margin-bottom: 0.5rem;
  }
  
  .npm-skeleton-line.short {
    width: 60%;
  }
  
  .npm-skeleton-tags {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 1.5rem;
  }
  
  .npm-skeleton-tag-item {
    width: 60px;
    height: 20px;
  }
  
  .npm-skeleton-chapters {
    margin-bottom: 1.5rem;
  }
  
  .npm-skeleton-chapter-item {
    width: 100%;
    height: 20px;
    margin-bottom: 0.5rem;
  }
  
  .npm-skeleton-rating {
    width: 100%;
    height: 50px;
    margin-bottom: 1.5rem;
  }
  
  .npm-skeleton-comments {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .npm-skeleton-comment-item {
    width: 100%;
    height: 40px;
  }
  