:root {
  --background-color: #2C2723;
  --content-background: #201E1B;
  --text-color: #E6D9CF;
  --accent-color: #B54332;
  --secondary-accent: #D1A3A4;
  --border-accent: #9f2112;
  --font-main: 'Montserrat', sans-serif;
  --font-content: 'Roboto', sans-serif;
  --nav-width: 800px;
}

.novel-page {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  background-color: var(--background-color);
  color: var(--text-color);
}

.novel-header {
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
  font-family: var(--font-main);
  align-items: flex-start;
}

.book-image {
  width: 400px;
  height: 550px;
  border-radius: 8px;
  overflow: hidden;
}

.book-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.book-info {
  flex-grow: 1;
}

.book-info h1 {
  font-family: var(--font-main);
  color: var(--secondary-accent);
  font-size: 3rem;
  margin: 0;
}

.korean-title {
  margin: 0.2rem;
  font-family: 'biryani';
  font-size: 1.5rem;
  color: var(--secondary-accent);
}

.stats {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap; /* Allows wrapping for smaller screens */
  color: var(--text-color);
}

.total-views, .total-chapters {
  display: flex;
  align-items: center;
  font-family: var(--font-main);
  color: var(--text-color);
  font-size: 1.5rem;
}

.total-views .icon, .total-chapters .icon {
  margin-right: 0.5rem;
  color: var(--text-color);
}




/* RATING IN STATS */
.rating-result {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Align to the left or center as needed */
  gap: 0.5rem; /* Adjust spacing between stars */
  color: var(--text-color); /* Ensure stars have the correct color */
  pointer-events: none; /* Make stars non-interactive */
}

.rating-result .stars {
  display: flex;
  align-items: center;
  margin-right: 0.5rem; /* Adjust spacing if needed */
}

.rating-result .star {
  color: var(--secondary-accent); /* Star color */
  margin-right: 0.3rem; /* Adjust spacing between stars */
  text-shadow: none; /* Remove any interactive shadows */
  cursor: default; /* Remove pointer cursor */
}

.rating-result .rating-value {
  color: var(--text-color); /* Numeric rating color */
}

.rating-result,
.rating-result .rating-value {
  font-size:1.5rem;
  margin-top:0.2rem;
  margin-bottom:0.2rem;
}



.novel-summary {
  background-color: var(--content-background);
  border-radius: 1rem;
  color: var(--accent-color);
  margin-bottom: 2rem;
  padding: 1rem;
  outline: 2px solid var(--secondary-accent);
  outline-offset: -2px;
  border-radius: 4px;
}

.summary-content {
  font-family: 'Roboto', sans-serif;
  color: var(--text-color);
  margin-top: -1rem;
  margin-bottom: 2rem;
  padding-bottom: 3rem;
  border-bottom: 2px solid var(--accent-color);
}

/* Specific styles for "AUTHOR," "STATUS," "STUDIO" */
.novel-summary p:has(strong), .status {  /* This uses a pseudo-class :has() to select only the paragraphs containing a <strong> tag */
  font-family: 'Roboto', sans-serif; /* Ensure the correct casing for 'roboto' */
  font-size: 1rem; /* Increase the font size */
  color:var(--text-color);
  margin-bottom: 0.5rem; /* Add some space at the bottom */
  padding: 0;
}

.status{
  margin: 0;
}
  

.author-studio {
  display: flex;
  gap: 30rem;
  align-items: stretch;
}

.novel-summary h2 {
  font-family: var(--font-main);
  font-size: 2.5rem;
  margin-bottom: 1rem;
  margin-top: 0.2rem;
  color: var(--accent-color);
}

.chapters-list h2 {
  font-family: var(--font-tenor);
  color: var(--text-color);
  font-size: 2.5rem;
  margin-bottom: 3rem;
  margin-top: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px ridge var(--accent-color);
  text-shadow: 0 0 4px rgba(255, 255, 255, 0.4), 0 0 8px rgba(255, 255, 255, 0.2); /* Softer shadow for reduced glow */
  transition: transform 0.3s ease, text-shadow 0.3s ease, color 0.3s ease; /* Smooth transitions */
}


.sort-icon {
  font-size: 2rem;
  margin-left: 0.5rem;
  cursor: pointer;
}

.chapters-list ul {
  list-style-type: none;
  padding: 0;
  margin-bottom: 10rem;
}



.chapters-list li {
  display: flex; /* Use Flexbox to align items horizontally */
  justify-content: space-between; /* Space between chapter title and time ago */
  align-items: center; /* Vertically center the content */
  padding: 0.5rem 0; /* Padding for spacing */
  border-bottom: 1px solid var(--accent-color); /* Add a bottom border */
  font-family: var(--font-montserrat);
  font-weight: 300;
  position: relative; /* Position relative to control pseudo-elements */
  width: 100%; /* Ensure full width */
}


/* chapters and chapter titles */
/* Scrollbar Styling */
.chapters-container {
  max-height: 600px; /* Set a fixed height for the container */
  overflow-y: auto; /* Enable vertical scrolling */
  padding-right: 0.5rem; /* Optional: Adjust padding for better UI */

  /* For Firefox */
  scrollbar-width: thin;  /* Makes the scrollbar narrower */
  scrollbar-color: var(--secondary-accent) var(--content-background);  /* Thumb color and track color */
}

/* For WebKit browsers (Chrome, Safari, Edge) */
.chapters-container::-webkit-scrollbar {
  width: 8px;  /* Width of the scrollbar */
  background-color: var(--content-background);  /* Background color of the scrollbar track */
}

.chapters-container::-webkit-scrollbar-thumb {
  background-color: var(--secondary-accent);  /* Color of the scrollbar thumb */
  border-radius: 10px;  /* Rounded corners of the scrollbar thumb */
  border: 2px solid var(--content-background); /* Border for a more pronounced effect */
}

.chapters-container::-webkit-scrollbar-thumb:hover {
  background-color: var(--accent-color);  /* Color when hovering over the scrollbar thumb */
}

/* For even more customization, you can add more styles to the scrollbar track */
.chapters-container::-webkit-scrollbar-track {
  background-color: var(--background-color);  /* Color of the scrollbar track */
  border-radius: 10px;  /* Rounded corners of the scrollbar track */
  margin: 4px;  /* Adds spacing around the scrollbar */
}


.chapters-list li a {
  color: #F2F0EB ;
  text-decoration: none;
  transition: text-shadow 0.3s ease; /* Smooth transition */
}

.chapters-list li a:hover {
  text-shadow: 0 0 8px var(--secondary-accent); /* Glow effect */
}




.novel-skeleton-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--background-color);
  transition: opacity 0.3s ease;
  opacity: 1;
  z-index: 1000; /* Ensures the skeleton is on top */
}

.novel-skeleton-overlay.hidden {
  opacity: 0;
  visibility: hidden;
}

.novel-content.hidden {
  opacity: 0;
}

.novel-content.visible {
  opacity: 1;
  transition: opacity 0.3s ease;
}






.ndp-follow-button {
  width: 100%; /* Full width of the container */
  display: flex;
  align-items: center;
  background: linear-gradient(90deg, var(--accent-color), var(--border-accent));
  border: none;
  border-radius: 8px;
  padding: 0.5rem 1rem;
  color: var(--text-color);
  font-family: 'Montserrat', sans-serif;
  font-size: 2rem;
  cursor: pointer;
  margin-bottom: -1rem;
  justify-content: center; /* Ensure button doesn't stretch full width */

}

.ndp-follow-button.green-gradient {
  background: linear-gradient(90deg, #52C755, #056608); /* Darker green gradient */
  color: var(--text-color); /* Ensure text is visible */
}


.ndp-follow-button .icon {
  margin-right: 0.5rem;
}
  
.ndp-follow-button .follow-text {
  margin-right: 0.5rem;
  font-weight: bold;


}
  
.ndp-follow-button .divider {
  margin: 0 0.5rem;
}
  
.ndp-follow-button .followers-count {
  margin-left: 0.5rem;
}

.ndp-follow-button .green-gradient .icon {
  color: var(--text-color); /* Ensure text is visible */
}

.ndp-follow-button.green-gradient .follow-text,
.ndp-follow-button .green-gradient .divider,
.ndp-follow-button .green-gradient .followers-count {
  color: var(--text-color); /* Ensure text is visible */
}






/* Individual Widget Styles */

.npd-container .widgets-container {
  padding: 0;  /* Remove padding for novel page */
  margin-top: 2rem;  /* Add space between follow button and widget */
}

.npd-container .etsy-widget-img {
  max-width: 250px;  /* Slightly smaller for novel page */
}





/* Style for the time ago text */
.time-ago {
  font-size: 0.75rem; /* Adjust the font size to your preference */
  color: var(--secondary-accent);
  font-style: italic;
  margin-top: 0.25rem; /* Add space between the chapter title and the time text */
  display: block; /* Display on a new line if necessary */
}
  
.tags-section {
  display: flex; /* Use flexbox to align items */
  align-items: center; /* Vertically center the items */
  margin-top: 1rem; /* Add some spacing from the previous section */
}

.tags-label {
  margin-right: 0.5rem; /* Add space between the label and the tags */
  font-size: 1rem; /* Increase the font size */
  font-weight: bold; /* Make the label stand out */
  display: inline-block; /* Ensure it stays on the same line */
}

.tags-container {
  display: flex; /* Use flexbox for layout */
  flex-wrap: wrap; /* Allow tags to wrap to the next line */
  gap: 0.5rem; /* Add gap between tag items */
}

.tag-item {
  background-color: var(--border-accent);  /* Tag background color */
  margin-top: 1rem;
  padding: 0.3rem 0.6rem; /* Add padding inside each tag */
  border-radius: 4px; /* Rounded corners */
  gap: 1rem;
  color:var(--text-color);
  text-decoration: none; /* Remove underline */
  font-family: 'Montserrat', sans-serif; /* Font family */
  font-size: 1rem; /* Adjust font size */
  transition: background-color 0.3s ease, color 0.3s ease; /* Hover transition */
  outline: 2px solid var(--secondary-accent);; /* Outline for tag */
  outline-offset: -2px; /* Position outline inside the border */
}

.tag-item:hover {
  background-color: var(--secondary-accent);
  color: var(--background-color);
}


  
  

