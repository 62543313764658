
/* CSS Variables (add these to your root) */
:root {
  --content-background-hover: rgba(255, 255, 255, 0.03);
  --spoiler-background: #202020;
  --spoiler-background-hover: #303030;
  --spoiler-background-revealed: #303030;
  --text-secondary: #888;
  --accent-color-rgb: 206, 86, 86; /* Replace with your accent color RGB values */
}

/* Comments Container */
.comments-section {
  margin: 0 auto;
  padding: 20px;
  background-color: var(--background-color);
  border-radius: 8px;
}

/* Header Styles */
.comments-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.comments-header-left {
  display: flex;
  align-items: center;
  gap: 16px;
}

.comments-count {
  font-size: 16px;
  color: var(--text-color);
  font-weight: 600;
}

.user-info {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 16px;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}

.username {
  font-size: 14px;
  color: var(--text-color);
  font-weight: 500;
}

.comments-auth-button {
  background: transparent;
  border: none;
  color: var(--accent-color);
  font-size: 16px;
  cursor: pointer;
  padding: 8px 16px;
  font-weight: 600;
  transition: color 0.2s ease, transform 0.1s ease;
}

.comments-auth-button:hover {
  color: var(--secondary-accent);
}

.comments-auth-button:active {
  transform: translateY(1px);
}

/* Comment Form */
.comment-form {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  margin: 16px 0;
  transition: opacity 0.2s ease;
}

.comment-form-login-prompt {
  width: 100%;
  background: var(--background-color);
  border: 1px solid var(--border-accent);
  border-radius: 24px;
  padding: 16px;
  text-align: center;
  color: var(--text-color);
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 14px;
  margin: 20px 0;
}

.comment-form-login-prompt:hover {
  border-color: var(--accent-color);
  background: rgba(206, 86, 86, 0.1);
}

.comment-form-login-prompt:focus-visible {
  outline: 2px solid var(--accent-color);
  outline-offset: 2px;
}

.comment-form-login-prompt:active {
  transform: scale(0.98);
}

/* Avatar Styles */
.comment-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
}

.avatar-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.default-profile-pic {
  width: 24px;
  height: 24px;
  color: var(--text-color);
  opacity: 0.7;
}

/* Input Area */
.comment-input-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.input-wrapper {
  position: relative;
  width: 100%;
}

.comment-input {
  width: calc(100% - 34px); /* Adjust width for info section */
  padding: 12px 16px;
  padding-bottom: 48px;
  background: var(--background-color);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: var(--text-color);
  font-size: 14px;
  line-height: 1.5;
  word-wrap: break-word;
  overflow-wrap: break-word;
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
  min-height: 100px;
  resize: vertical;
}

.comment-input:focus {
  outline: none;
  border-color: var(--accent-color);
  box-shadow: 0 0 0 2px rgba(var(--accent-color-rgb), 0.1);
}

.comment-input:focus-visible {
  outline: 2px solid var(--accent-color);
  outline-offset: 2px;
}

.comment-input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

/* Inline Image Styles */
.inline-comment-image {
  display: inline-block;
  max-width: 300px;
  max-height: 300px;
  border-radius: 4px;
  margin: 4px;
  vertical-align: middle;
  object-fit: contain;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.inline-comment-image:hover {
  transform: scale(1.02);
}

/* Input Tools */
.input-tools {
  position: absolute;
  bottom: 12px;
  left: 16px;
  right: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--background-color);
  padding: 4px 0;
}

.left-tools {
  display: flex;
  align-items: center;
  gap: 12px;
}

.tool-button {
  background: none;
  border: none;
  padding: 8px;
  color: var(--text-color);
  opacity: 0.7;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.tool-button:hover:not(:disabled) {
  opacity: 1;
  background: rgba(255, 255, 255, 0.1);
}

.tool-button:focus-visible {
  outline: 2px solid var(--accent-color);
  outline-offset: 2px;
}

.tool-button:active:not(:disabled) {
  transform: translateY(1px);
}

.tool-button.submit-button {
  color: var(--accent-color);
  font-weight: 500;
}

.tool-button.submit-button:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.tool-button.loading {
  opacity: 0.7;
  cursor: wait;
}





/* Sort Controls */
.comment-sort {
  display: flex;
  justify-content: flex-end;
  gap: 24px;
  padding: 16px 0;
  border-bottom: 1px solid var(--border-accent);
  margin-bottom: 24px;
}

.sort-button {
  background: transparent;
  border: none;
  color: var(--text-color);
  font-size: 14px;
  font-weight: 600;
  padding: 8px 12px;
  cursor: pointer;
  opacity: 0.7;
  position: relative;
  transition: all 0.2s ease;
}

.sort-button:hover {
  opacity: 1;
  color: var(--accent-color);
}

.sort-button.active {
  opacity: 1;
  color: var(--accent-color);
}

.sort-button.active::after {
  content: '';
  position: absolute;
  bottom: -17px;
  left: 0;
  right: 0;
  height: 2px;
  background-color: var(--accent-color);
  animation: slideIn 0.2s ease;
  transform-origin: left;
}

/* Comment Styles */
.comments-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.comments-empty {
  text-align: center;
  padding: 32px;
  color: var(--text-color);
  opacity: 0.7;
  font-style: italic;
}

.comment {
  padding: 16px;
  background-color: var(--content-background);
  border-radius: 8px;
  margin-bottom: 16px;
  transition: background-color 0.2s ease;
}

.comment:hover {
  background-color: var(--content-background-hover);
}

.comment-main-content {
  display: flex;
  gap: 16px;
  width: 100%;
}

.comment-content {
  flex: 1;
  min-width: 0; /* Prevents flex item from overflowing */
}

.comment-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.comment-info {
  font-size: 14px;
  color: var(--text-secondary);
}

.comment-author {
  font-weight: 600;
  color: var(--text-color);
  font-size: 15px;
}

.comment-time {
  margin-left: 8px;
  font-size: 13px;
  color: var(--text-secondary);
}

.comment-text {
  color: var(--text-color);
  font-size: 14px;
  line-height: 1.6;
  margin-bottom: 12px;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

/* Comment Images */
.comment-embedded-image {
  max-width: 100%;
  max-height: 300px;
  border-radius: 8px;
  margin: 12px 0;
  display: block;
  transition: transform 0.2s ease;
  cursor: pointer;
}

.comment-embedded-image:hover {
  transform: scale(1.02);
}

.comment-embedded-image-error {
  display: inline-block;
  padding: 8px;
  background: rgba(255, 0, 0, 0.1);
  border-radius: 4px;
  color: var(--error-color);
  font-size: 12px;
  margin: 4px 0;
}

/* Comment Actions */
.comment-actions {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 12px;
}

.action-button {
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  color: var(--text-color);
  font-size: 14px;
  cursor: pointer;
  padding: 6px 8px;
  gap: 6px;
  border-radius: 4px;
  transition: all 0.2s ease;
  transform: translateY(0);
}

.action-button:hover {
  color: var(--accent-color);
  background: rgba(255, 255, 255, 0.05);
}

.action-button:active {
  transform: translateY(1px);
}

.action-button:focus-visible {
  outline: 2px solid var(--accent-color);
  outline-offset: 2px;
}

.action-button .icon {
  font-size: 16px;
}

.action-button span {
  font-size: 13px;
}

.action-button.voted {
  color: var(--accent-color);
  font-weight: 600;
}

/* Replies */
.replies-container {
  position: relative;
  margin-left: 24px;
  margin-top: 16px;
  padding-left: 24px;
  border-left: 2px solid var(--border-accent);
  transition: all 0.2s ease;
}

.replies-container::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 2px;
  background: var(--border-accent);
  opacity: 0.3;
  transition: all 0.2s ease;
}

.replies-container:hover::before {
  opacity: 0.6;
  background: var(--accent-color);
}

.comment-reply {
  margin-top: 16px;
}

.comment-reply .comment-avatar {
  width: 32px;
  height: 32px;
}

.comment-reply .default-profile-pic {
  width: 20px;
  height: 20px;
}

/* Spoiler Text */
.spoiler-text {
  background-color: var(--spoiler-background);
  color: transparent;
  padding: 2px 6px;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  transition: all 0.2s ease;
  display: inline-block;
  margin: 0 2px;
  position: relative;
}

.spoiler-text:hover {
  background-color: var(--spoiler-background-hover);
}

.spoiler-text.revealed {
  background-color: var(--spoiler-background-revealed);
  color: var(--text-color);
}

.spoiler-text:not(.revealed):hover::after {
  content: 'Click to reveal';
  position: absolute;
  top: -24px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  background: rgba(0, 0, 0, 0.8);
  padding: 4px 8px;
  border-radius: 4px;
  color: white;
  white-space: nowrap;
}

/* Loading States */
.comments-loading {
  text-align: center;
  padding: 24px;
  color: var(--text-color);
  opacity: 0.7;
}

.icon-spinner {
  animation: spin 1s linear infinite;
}


.comment-loading-skeleton {
  background: linear-gradient(90deg, 
    var(--background-color) 25%, 
    rgba(255, 255, 255, 0.1) 50%, 
    var(--background-color) 75%
  );
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
}

/* Error States */
.comments-error {
  background: var(--content-background);
  border: 1px solid var(--accent-color);
  border-radius: 8px;
  padding: 16px 20px;
  margin: 16px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--accent-color);
  font-size: 14px;
}

/* Upload Error Styles */
.upload-error {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(255, 0, 0, 0.1);
  color: var(--error-color);
  padding: 8px 12px;
  border-radius: 4px;
  margin: 8px 0;
  font-size: 13px;
}

.upload-error .clear-error {
  background: none;
  border: none;
  color: inherit;
  padding: 4px;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.2s ease;
}

.upload-error .clear-error:hover {
  opacity: 1;
}

/* Buttons */
.retry-button,
.load-more-button {
  background: transparent;
  border: 1px solid var(--accent-color);
  color: var(--accent-color);
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-weight: 500;
}

.retry-button:hover,
.load-more-button:hover {
  background: rgba(var(--accent-color-rgb), 0.1);
}

.retry-button:active,
.load-more-button:active {
  transform: scale(0.98);
}

.load-more-replies {
  background: none;
  border: none;
  color: var(--accent-color);
  cursor: pointer;
  font-size: 13px;
  padding: 8px;
  text-align: left;
  display: inline-block;
  transition: color 0.2s ease, transform 0.1s ease;
}

.load-more-replies:hover {
  color: var(--secondary-accent);
}

.load-more-replies:active {
  transform: translate
}

/* Utility Classes */
.hidden {
  display: none !important;
}

/* Animations */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideUp {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
}

@keyframes slideIn {
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

/* Motion-reduced preferences */
@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

/* Media Queries */
@media (max-width: 768px) {
  /* Header Adjustments */
  .comments-header-left {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  .user-info {
    padding-left: 0;
    border-left: none;
    width: 100%;
  }

  /* Comment Form Adjustments */
  .comment-form {
    gap: 12px;
  }

  .comment-avatar {
    width: 40px;
    height: 40px;
  }

  .comment-input {
    padding: 10px 14px;
    padding-bottom: 44px;
    font-size: 13px;
  }

  .input-tools {
    bottom: 8px;
    left: 12px;
    right: 12px;
  }

  /* Sort Controls */
  .comment-sort {
    gap: 16px;
    justify-content: center;
    padding: 12px 0;
  }

  .sort-button {
    padding: 6px 10px;
    font-size: 13px;
  }

  /* Comment Adjustments */
  .comment {
    padding: 12px;
  }

  .comment-main-content {
    gap: 12px;
  }

  .comment-text {
    font-size: 13px;
  }

  /* Actions Adjustments */
  .comment-actions {
    gap: 12px;
  }

  .action-button {
    padding: 8px;
    min-height: 36px; /* Better touch targets */
  }

  /* Reply Adjustments */
  .replies-container {
    margin-left: 16px;
    padding-left: 16px;
  }

  .comment-reply .comment-avatar {
    width: 28px;
    height: 28px;
  }

  /* Image Adjustments */
  .comment-embedded-image {
    max-height: 200px;
  }

  /* Error & Loading States */
  .comments-error {
    flex-direction: column;
    gap: 12px;
    text-align: center;
    padding: 12px;
  }

  .retry-button,
  .load-more-button {
    width: 100%;
    padding: 10px;
  }
}


/* High-contrast mode support */
@media (forced-colors: active) {
  .comment-input,
  .action-button,
  .tool-button,
  .sort-button {
    border: 1px solid ButtonBorder;
  }

  .comment {
    border: 1px solid ButtonBorder;
  }

  .spoiler-text {
    border: 1px dashed ButtonBorder;
  }
}



