:root {
    --background-color: #2C2723;
    --content-background: #201E1B;
    --text-color: #E6D9CF;
    --accent-color: #ce5656;
    --secondary-accent: #FFB3BA;
    --border-accent: #9f2112;
    --font-main: 'Montserrat', sans-serif;
    --font-content: 'Roboto', sans-serif;
  }


/* Center and style for "Rate the novel" text */

.star-rating {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0;
  }


/* Center the star container */
.star-rating .stars {
    display: flex;
    justify-content: center;
    margin-bottom: 5rem;
}

/* Style for stars */
.star-rating .star  {
    color: var(--secondary-accent);
    cursor: pointer;
    transition: color 200ms;
    margin: 0 0.2rem; /* Add some spacing between stars */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Add shadow for depth */

}

/* Hover effects for stars */
.star-rating .star:hover {
    transform: scale(1.2);
    color: var(--accent-color);
}

/* Center and style for "Rate the novel" text */

.star-rating p {
    text-align: center;
    font-size: 1.5rem; /* Increased font size */
    color: var(--text-color);
    font-weight: bold; /* Make text bolder */
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5); /* Add subtle shadow */
    margin-top: 8rem; /* Adjust top margin */
    margin-bottom: 0.5rem;
}



/* Hide default radio button inputs */

.star.filled {
    color: var(--accent-color);
    fill: var(--accent-color);
    text-shadow: 0px 0px 5px var(--accent-color);
}

.star-rating input {
    display: none;
}

/* Thank you message styles */
.thank-you-message {
    text-align: center;
    font-size: 1.2rem;
    color: var(--text-color);    
    margin-top: 1rem;  /* Adjusted to appear closer */
    font-weight: bold;
    opacity: 0;
    animation: fadeIn 0.5s forwards; /* Animation for dynamic effect */
}

.rated-message {
    color: var(--text-color);    
    font-size: 0.8rem;
    margin-bottom: 8px;
    animation: fadeIn 0.5s forwards; /* Animation for dynamic effect */

  }



/* Animation for thank you message */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px); /* Slide in from below */
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
