.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-bottom: 2px solid var(--accent-color);
    background-color: var(--background-color);
}

.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

/* Logo Styling */
.navbar-logo,
.navbar-logo .logo-link,
.navbar-logo .logo-link:visited,
.navbar-logo .logo-link:hover,
.navbar-logo .logo-link:active  {
    font-family: 'Roxborough CF';
    font-size: 3rem;
    line-height: 1.2;
    position: relative;
    background: linear-gradient(45deg, #fac3ba, #7a9cc6 );
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    text-shadow: 0 0 8px rgba(255, 255, 255, 0.6), 0 0 15px rgba(255, 255, 255, 0.4);
    transition: transform 0.3s ease, text-shadow 0.3s ease, color 0.3s ease;
    cursor: pointer;
}

.navbar-logo:hover {
    transform: scale(1.05);
    text-shadow: 0 0 12px rgba(255, 255, 255, 0.8), 0 0 25px rgba(255, 255, 255, 0.6);
}

/* Inner Shadow Effect Using Pseudo-Element */
.navbar-logo::after {
    content: "chaeknovels";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: inherit;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.2);
    z-index: -1;
    pointer-events: none;
}

/* Styling for "TRANSLATED" */
.navbar-logo span {
    display: block;
    font-family: 'Biryani', sans-serif;
    font-size: 1rem;
    text-align: right;
    position: absolute;
    right: 0;
    width: 100%;
    margin-top: -0.2rem;
    letter-spacing: 2px;
    text-transform: uppercase;
    opacity: 0.9;
    color: var(--accent-color);    
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.3);
}

.navbar-logo .logo-link {
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
}

.navbar-menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
}

.menu-close {
    display: none;
}

.navbar-links {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.navbar-links li {
    margin: 0 1rem;
}

.navbar-links a,
.navbar-links .navbar-link-button {
    color: var(--text-color);
    text-decoration: none;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.2rem;
    border: none;
    background: none;
    cursor: pointer;
    padding: 0;
}

.navbar-search {
    position: relative;
    margin-left: 1rem;
}

.navbar-search input {
    padding: 0.5rem 1rem;
    background-color: var(--content-background);
    color: var(--text-color);
    border: 2px solid var(--accent-color);
    border-radius: 20px;
    width: 200px;
    font-size: 1rem;
}

.navbar-user {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.profile-wrapper {
    display: flex;
    align-items: center;
}

.navbar-profile-pic,
.default-profile-pic {
    width: 35px;
    height: 35px;
    border-radius: 8px;
    object-fit: cover;
    margin-right: 0.5rem;
    border: 2px solid var(--accent-color);
    vertical-align: middle;
}

.username {
    display: none;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: var(--content-background);
    border: 1px solid var(--accent-color);
    border-radius: 8px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    display: none;
    z-index: 1000;
}

.dropdown-menu.open {
    display: block;
}

.dropdown-menu a,
.dropdown-menu button {
    display: block;
    width: 100%;
    padding: 0.75rem 1rem;
    color: var(--text-color);
    text-decoration: none;
    background: none;
    border: none;
    text-align: left;
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    cursor: pointer;
}

.menu-toggle {
    display: none;
}



@media screen and (max-width: 768px) {
    .navbar-container {
        flex-wrap: nowrap;
    }

    .navbar-logo,
    .navbar-logo .logo-link,
    .navbar-logo .logo-link:visited,
    .navbar-logo .logo-link:hover,
    .navbar-logo .logo-link:active {
        font-size: 2rem;
    }

    .menu-toggle {
        display: block;
        background: none;
        border: none;
        color: var(--text-color);
        font-size: 1.8rem;
        cursor: pointer;
        margin-left: 1rem;
        padding: 0;
    }

    .menu-toggle .navbar-profile-pic,
    .menu-toggle .default-profile-pic,
    .navbar-profile-pic,
    .default-profile-pic {
        width: 35px;
        height: 35px;
        border-radius: 8px;
        object-fit: cover;
        margin-right: 0.5rem;
    }

    .navbar-menu {
        position: fixed;
        top: 0;
        right: -100%;
        height: 100vh;
        width: 80%;
        max-width: 300px;
        padding: 2rem 1.5rem;
        background-color: var(--background-color);
        box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
        transition: right 0.3s ease-in-out;
        z-index: 1000;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .navbar-menu.open {
        right: 0;
    }

    .menu-close {
        display: block;
        position: absolute;
        top: 1rem;
        right: 1rem;
        background: none;
        border: none;
        color: var(--text-color);
        font-size: 1.8rem;
        cursor: pointer;
        transition: color 0.3s ease;
    }

    .menu-close:hover {
        color: var(--accent-color);
    }

    .navbar-links {
        flex-direction: column;
        width: 100%;
        margin-top: 2rem;
    }

    .navbar-links li {
        margin: 0.5rem;
        margin-bottom: 0.5rem;
       
    }

    .navbar-links a,
    .navbar-links .navbar-link-button,
    .user-menu-mobile a,
    .user-menu-mobile button {
        color: var(--text-color);
        text-decoration: none;
        background: none;
        border: none;
        font-family: 'Montserrat', sans-serif;
        font-size: 1rem; /* Ensure both have the same size */
        cursor: pointer;
        transition: color 0.3s ease;
    }

    .user-menu-mobile a:hover,
    .user-menu-mobile button:hover {
        color: var(--accent-color);
    }

    .navbar-links a:hover,
    .navbar-links .navbar-link-button:hover {
        color: var(--accent-color);
    }

    .user-menu-mobile {
        list-style: none;
        padding-left: 2rem;
        margin-top: 0.5rem;
    }

    .user-menu-mobile li {
        margin: 0.5rem 0;
    }


    .navbar-search {
        width: 100%;
        margin: 1.5rem 0;
        padding-top: 1.5rem;
        border-top: 1px solid var(--accent-color);
    }

    .navbar-search input {
        width: calc(100% - 2rem); /* Subtract horizontal padding from width */
        padding: 0.75rem 1rem;
        font-size: 1rem;
        background-color: transparent;
        border: 2px solid var(--accent-color);
        border-radius: 20px;
        color: var(--text-color);
        box-sizing: border-box; /* Include padding and border in the element's total width */
        transition: border-color 0.3s ease;
    }

    .navbar-search input:focus {
        outline: none;
        border-color: var(--text-color);
    }

    .navbar-user {
        flex-direction: column;
        align-items: flex-start;
    }

    .profile-wrapper {
        display: flex;
        align-items: center;
        margin-top: 1rem;
    }

    .username {
        display: block; /* Ensure the username shows on mobile */
        font-family: 'Montserrat', sans-serif;
        font-size: 1rem;
        margin-left: 0.5rem; /* Space between avatar and username */
    }
    
    .navbar-profile-pic,
    .default-profile-pic {
        margin-right: 0.5rem;
    }
   


   

    .dropdown-menu {
        display: none !important;
    }
}
