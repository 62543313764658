:root {
    --background-color: #2C2723;
    --content-background: #201E1B;
    --text-color: #E6D9CF;
    --accent-color: #ce5656;
    --secondary-accent: #FFB3BA;
    --border-accent: #9f2112;
    --font-main: 'Montserrat', sans-serif;
    --font-content: 'Roboto', sans-serif;
  }
  
  .progress-bar-container {
    width: 100%;
    height: 4px;
    background-color: var(--background-color);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
  }
  
  .progress-bar {
    height: 100%;
    background-color: var(--accent-color);
    transition: width 0.3s ease;
  }