:root {
  --background-color: #2C2723;
  --content-background: #201E1B;
  --text-color: #E6D9CF;
  --accent-color: #ce5656;
  --secondary-accent: #FFB3BA;
  --border-accent: #9f2112;
  --font-main: 'Montserrat', sans-serif;
  --font-content: 'Roboto', sans-serif;
  --nav-width: 800px;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: var(--font-main);
}

.chapter-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
}

.book-title {
  font-size: 2.5rem;
  color: var(--secondary-accent);
  text-align: center;
  margin-bottom: 2rem;
}

.chapter-content {
  background-color: var(--content-background);
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.chapter-title {
  font-size: clamp(16px, 4vw, 24px);
  color: var(--accent-color);
  margin-bottom: 1.5rem;
  border-bottom: 2px solid var(--accent-color);
  padding-bottom: 0.5rem;
  max-width: 100%;
}

.chapter-text {
  font-family: var(--font-content);
  line-height: 1.6;
  font-size: 1rem;
}

/* Consistent Separator Style for All Screen Sizes */
.chapter-text p.separator {
  text-align: center; /* Center the text within the separator */
  margin: 1.5rem auto; /* Center the separator element horizontally */
  font-size: 1.2rem; /* Set a font size for visual separation */
  letter-spacing: 0.2rem; /* Increase spacing between characters */
  width: 100%; /* Ensure it takes full width of the container */
  display: block; /* Set as block element to ensure full-width alignment */
  padding-bottom: 0.5rem; /* Optional: Add padding if needed */
}





.chapter-nav,
.bottom-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 0;
  flex-wrap: nowrap;
  gap: 1rem;
}

.chapter-select {
  flex: 1;
}

.chapter-dropdown {
  width: 100%;
  padding: 0.5rem 2.5rem 0.5rem 1rem;
  background-color: var(--content-background);
  color: var(--text-color);
  border: 2px solid var(--accent-color); /* Match the border style with the search bar */
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg fill="%23E6D9CF" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
  background-repeat: no-repeat;
  background-position: right 0.7em top 50%;
  background-size: 1.5em auto;
  transition: background-color 0.3s ease, color 0.3s ease;
}



.chapter-dropdown:focus {
  outline: none;
  background-color: var(--content-background);
  color: var(--text-color);
}


.top-nav-buttons {
  display: flex;
  gap: 0.5rem;
  transition: opacity 0.3s ease; /* Smooth transition */
  

  
}


.nav-button,
.home-button {
  flex: 1;
  padding: 0.5rem 1rem;
  background-color: var(--content-background);
  color: var(--text-color);
  border: 2px solid var(--accent-color);
  text-decoration: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  transition: background-color 0.3s ease, color 0.3s ease;
  max-width: 100px; /* Reduced from 200px for better layout */
  padding: 0.5rem 1rem;
}

.nav-button:hover,
.home-button:hover,
.nav-button:focus,
.home-button:focus {
  background-color: var(--secondary-accent);
  color: var(--background-color);
  outline: none;
}

.nav-button.active,
.home-button.active {
  background-color: var(--secondary-accent);
  color: var(--background-color);
  outline: none;
}

.nav-icon {
  margin: 0 0.5rem;
}

/* Ensure two buttons are always visible in top navigation for desktop */
.chapter-nav .nav-buttons .nav-button {
  display: flex;
}

/* Hide the third button in top navigation if it exists */
.chapter-nav .nav-buttons .nav-button:nth-child(3) {
  display: none;
}



/* Hide chapter select in bottom navigation */
.bottom-nav .chapter-select {
  display: none;
}


.bottom-nav {
  display: flex;
  justify-content: center; /* Center the content horizontally */
  align-items: center;
  margin: 2rem 0;
  width: 100%;
}

.bottom-nav .top-nav-buttons {
  display: flex;
  gap: 0.5rem;
  justify-content: center; /* Center the buttons within their container */
  width: auto; /* Allow the container to shrink to fit its content */
}

.bottom-nav .nav-button,
.bottom-nav .home-button {
  flex: 0 1 auto; /* Don't allow buttons to grow, but allow them to shrink */
  max-width: 100px;
}


.chapter-text h2 {
  font-family: var(--font-main);
  color: var(--secondary-accent);
  border-top: 1px solid var(--border-accent); /* Adjust the color and thickness as needed */

  font-size: 1rem;

}

.chapter-text h2 + p + b {
  font-family: var(--font-main);  
  color: var(--text-color);
  font-size: 0.8rem; /* Example property */
}

.chapter-content .chapter-text p span {
  color: var(--text-color);
}




















/* Mobile-specific styles */
@media screen and (max-width: 768px) {
  body {
    background-color: var(--content-background);
    overflow-x: hidden; /* Prevent horizontal overflow globally */


  }

  .chapter-page {
    margin: 0;
    width: 100%;
    padding: 0.5rem;
    box-sizing: border-box; /* Include padding in width calculation */
    overflow: hidden; /* Hide any overflow */

  }

  .book-title {
    font-size: clamp(1.5rem, 2vw + 1rem, 3rem);
    margin: 1rem auto;
    line-height: 1;
    width: fit-content;
    max-width: 100%;
    margin-top: 1rem;
    overflow: hidden; /* Hide overflow */

  }

  .chapter-content {
    padding: 0.8rem;
    border-radius: 0;
    box-shadow: none;
    width: 100%;
    max-width: 100%;
    margin: 0;
    box-sizing: border-box;
  }


  .chapter-text {
    font-family: var(--font-content);
    line-height: 1.6;
    font-size: 1rem;
    padding: 0;
    max-width: 100%;
    word-wrap: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
    box-sizing: border-box;

  }

  .chapter-content::before,
  .chapter-content::after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background-color: var(--accent-color);
    margin: 1rem 0;
  }

  .bottom-nav {
    flex-direction: column;
    align-items: stretch;
    margin: 1rem 0;
    width: 100%;
  }

  .chapter-nav {
    flex-wrap: wrap; /* Allow wrapping into multiple rows on mobile */
    margin: 0;
  }

  .top-nav-buttons {
    flex-direction: row; /* Stack buttons vertically on mobile */
    width: 100%; /* Ensure they take up the full width */
  }

  .chapter-select {
    width: 100%;
  }

  .chapter-dropdown {
    width: 100%;
    padding: 0.5rem;
    font-size: 0.9rem;
    border-radius: 8px;
    background-color: var(--content-background);
    color: var(--text-color);
    text-overflow: ellipsis; /* Ensure text overflow is handled with ellipsis */
    white-space: nowrap; /* Prevent text from wrapping to multiple lines */
    overflow: hidden; /* Hide overflow text */
  }
  
  .chapter-dropdown option {
    text-overflow: ellipsis; /* Ensure option text is truncated with ellipsis */
    overflow: hidden; /* Hide overflow text */
    white-space: nowrap; /* Prevent text wrapping */
  }
  

  .nav-button,
  .home-button {
    max-width: calc(50% - 0.25rem);
    padding: 0.30rem 0;
    font-size: 0.8rem;

  }

  /* Show all buttons in mobile view */
  .chapter-nav .top-nav-buttons .nav-button:nth-child(2) {
    display: flex;
  }

  .chapter-title {
    display: none;
  }


  .bottom-nav {
    flex-direction: row;
    justify-content: center;
  }

  .bottom-nav .top-nav-buttons {
    display: flex;
    justify-content: space-between; /* Ensure even spacing */
    margin-top: 1rem;
    width: 100%;
    margin-bottom: 8rem;

  }

  .bottom-nav .nav-button,
  .bottom-nav .home-button {
    max-width: none; /* Remove the max-width restriction */
    width: 100%; /* Ensure buttons fill the width of the container */
    padding: 0.5rem 0;
    font-size: 0.9rem;;
  }



  /* Ensure all text elements within chapter-text stay within bounds */
  .chapter-text * {
    max-width: 100%;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }


  

 

  .mobile-scroll-arrows {
    position: fixed;
    right: 16px;
    bottom: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    z-index: 1000;
  }
  
  .scroll-arrow {
    width: 430px;
    height: 40px;
    background-color: rgba(46, 46, 46, 0.5); /* semi-transparent background */
    border: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .scroll-arrow:hover,
  .scroll-arrow:focus {
    background-color: rgba(46, 46, 46, 0.8); /* darker on hover/focus */
  }
  
  .scroll-arrow svg {
    color: #ffffff;
  }





}