.about-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: var(--font-content);
}

.about-title {
  color: var(--secondary-accent);
  font-family: var(--font-main);
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 2rem;
}

.about-content {
  background-color: var(--content-background);
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.about-content h1, .about-content h2 {
  color: var(--secondary-accent);
  font-family: var(--font-main);
  margin-bottom: 1rem;
}

.about-content h2 {
  font-size: 1.8rem;
  margin-top: 2rem;
}

.about-content p {
  line-height: 1.6;
  margin-top: 0;
}

.about-content a {
  color: var(--accent-color);
  text-decoration: none;
}

.about-content a:hover {
  text-decoration: underline;
}

.about-content h3 {
  margin: 0;
}

.MsoList4CxSpFirst,
.MsoList4CxSpMiddle,
.MsoList4CxSpLast {
  margin-left: 2rem; /* Adjust the indentation */
}

.separator {
  margin: 1rem 0; /* Adds some margin around the separator */
  height: 0; /* Keeps the height to 0 */
  line-height: 0; /* Removes line height */
  text-align: center; /* Centers the line if you add text */
}

.about-content h3,
.about-content h4 {
  margin: 0;
  color: var(--accent-color);
  font-size: 1rem;
}

.error,
.loading {
  color: var(--accent-color);
  text-align: center;
  font-size: 1.2rem;
}

.loading {
  color: var(--text-color);
}

/* Mobile Styles */
@media (max-width: 768px) {
  .about-page {
    padding: 0.5rem;
  }

  .about-title {
    font-size: 2rem;
    margin-bottom: 1.5rem; /* Adjust margin for better spacing */
  }

  .about-content {
    padding: 1rem;
    border-radius: 6px; /* Slightly reduce border-radius for smaller screens */
  }


  .about-content h2 {
    font-size: 1.2rem;
    margin-top: 1.5rem; /* Adjust margin for better spacing */
  }

  .about-content p {
    font-size: 1rem; /* Adjust font size for readability */
    line-height: 1.3; /* Adjust line-height for readability */
    margin-top: 0.5rem; /* Add some margin for spacing */
  }

  .about-content a {
    font-size: 1rem; /* Adjust font size for links */
  }

  .separator {
    margin: 0.5rem 0; /* Adjust separator margin for better spacing */
  }

  .error,
  .loading {
    font-size: 1rem; /* Adjust font size for error and loading messages */
  }
}
