:root {
  --background-color: #2C2723;
  --content-background: #201E1B;
  --text-color: #E6D9CF;
  --accent-color: #ce5656;
  --secondary-accent: #FFB3BA;
  --border-accent: #9f2112;
  --font-main: 'Montserrat', sans-serif;
  --font-content: 'Roboto', sans-serif;
}

.skeleton-chapter-content {
  padding: 20px;
  background-color: var(--content-background);
  font-family: var(--font-content);
}

.skeleton-chapter-title {
  height: 40px;
  background-color: var(--background-color);
  margin-bottom: 20px;
  width: 80%;
}

.skeleton-paragraph {
  height: 20px;
  background-color: var(--background-color);
  margin-bottom: 15px;
}

.skeleton-chapter-title, .skeleton-paragraph {
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.6;
  }
}